export default (navigateFunc, to, componentToAnimate) => {
    
    window.setImmediate = window.setTimeout;
    return new Promise(resolve => {
        if (!componentToAnimate) {
            navigateFunc(to);
            return resolve();
        };
        componentToAnimate.animate([
            { opacity: getComputedStyle(componentToAnimate).opacity },
            { opacity: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease-in-out"
        }).onfinish = () => {
            navigateFunc(to);
            setImmediate(() => {
                componentToAnimate.animate([
                    { opacity: getComputedStyle(componentToAnimate).opacity },
                    { opacity: 1 }
                ], {
                    duration: 300,
                    iterations: 1,
                    fill: "both",
                    easing: "ease-in-out"
                }).onfinish = () => {
                    resolve();
                };
            });
        };
    });
};