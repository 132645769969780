import React from "react";
import "./index.scss";

import { MD5 } from "crypto-js";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import useDefer from "../../../modules/hooks/useDefer";

import Spinner from "../../../components/customComponents/Spinner";
import Progressbar from "../../../components/customComponents/Progressbar";
import { FilteredCustomTable } from "../../../components/customComponents/Table";

const UserSMS = () => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    
    const timestampRef = React.useRef();
    const curOnScreen = useOnScreen();
    const curDefer = useDefer();
    const curNavigate = useNavigate();

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/proxySMSAssigns/getAssignedNumbers`,
            data: {
                limit: 20,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);

            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = (ts) => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (!canPaginate) return;
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/proxySMSAssigns/getAssignedNumbers`,
            data: {
                limit: 20,
                offset: data.data.length
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;

            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        curDefer(() => {
            let ts = Date.now();
            timestampRef.current = ts;
            continueData(ts);
        }, 500);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, []);

    return <div className="route__user__sms">
        <FilteredCustomTable
            style={{width: "100%", columnGap: "50px"}}
            accent="#6C5DD3"
            theme="dark"
            headers={["Phone number", "Total SMS received", "Last received date", "Status", ""]}
            customColumns={["auto", "auto", "auto", "auto", "1fr"]}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "There was an error while fetching data."}]];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: item.PhoneNumber},
                        {keyID: item.ID, type: "text", text: item.Total},
                        {keyID: item.ID, type: "text", text: item.LastReceived ? moment(item.LastReceived).toDate().toLocaleString() : "Never"},
                        {keyID: item.ID, type: "text", text: item.Active ? <span style={{color: "#6e6"}}>Active</span> : <span style={{color: "#e66"}}>Inactive</span>},
                        {keyID: item.ID, type: "group", group: item.Active ? [
                            {keyID: item.ID, type: "button", text: "View SMS", onClick: e => animateBox(e, <UserSMS_Messages ID={item.ID} data={item} />)}
                        ] : []},
                        (item.Description ? {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "text", text: item.Description, style: {color: "#a49d9d"}}
                        ]} : null)
                    ].filter(t => t));
                };

                if (out.length === 0) return [[{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]];
                return out;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{width: "1px", height: "1px", opacity: 0}}></div>}
    </div>
};

const UserSMS_Messages = props => {
    const [data, setData] = React.useState();
    const [timer, setTimer] = React.useState(0);
    const [oldMessages, setOldMessages] = React.useState([]);

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/proxySMSAssigns/getSMSMessages`,
            data: {
                ID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                return setData({status: "ok", data: sortData(res.data.data).map(elem => performHash(elem))});
            };
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        }).finally(() => {
            setTimer(10);
        });
    };

    const sortData = data => {
        return data.sort((a, b) => {
            return moment(a.Date).isBefore(moment(b.Date)) ? 1 : -1;
        });
    };

    const performHash = (item) => {
        let tmp = {...item};
        delete tmp.Hash;
        item.Hash = MD5(encodeURI(JSON.stringify(tmp))).toString();

        return item;
    };

    const initOldMessages = () => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (oldMessages.length !== 0) return;

        setOldMessages([...data.data.map(d => d.Hash), "-"]);
    };

    const checkIfNewMessage = (message) => {
        if (oldMessages.includes(message.Hash)) return {};

        return {
            backgroundColor: "rgb(56, 70, 54)"
        };
    };

    React.useEffect(() => {
        if (timer <= 0) {
            setTimeout(() => getData(), 1000);
            return;
        };

        let curTimeout = setTimeout(() => {
            setTimer(t => t-1);
        }, 1000);

        return () => clearTimeout(curTimeout);
    }, [timer]);

    React.useEffect(() => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (oldMessages.length !== 0) return;

        initOldMessages();
    }, [data, oldMessages]);

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap" style={{width: "800px"}}>
            <div className="genericModal__wrap__spinner" style={{
                opacity: data ? 0 : 1,
                pointerEvents: data ? "none" : "all"
            }}>
                <Spinner color="white" />
            </div>

            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left" style={{display: "flex", alignItems: "center", gap: "10px"}}>
                    <span>View SMS {props.data.PhoneNumber}</span>
                    <Progressbar color="white" maximum={10} value={timer} style={{width: "21px", height: "21px"}} />
                    <span style={{fontSize: "14px", color: "gray"}}>(auto reload)</span>
                </div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            {data && <>
                {data.status === "ok" ? <>
                    {data.data.length === 0 ? <p>No messages to show...</p> : <div className="route__user__sms__messages">
                        {data.data.map(d => {
                            return <div className="route__user__sms__messages__message" key={d.ID ? `message-${d.ID}` : d.Hash} style={{...checkIfNewMessage(d)}}>
                                <p className="route__user__sms__messages__message__name"><span>{d.From}</span> <span>{moment(d.Date).toDate().toLocaleString()}</span></p>
                                <p className="route__user__sms__messages__message__text">{d.Message}</p>
                            </div>;
                        })}
                    </div>}
                </> : <>
                    <p style={{color: "#e66"}}>There was an error while fetching messages!</p>
                </>}
            </>}
        </div>
    </div>
};

export default UserSMS;