import React from "react";
import "./index.scss";

import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation"
import { setTimestamp } from "../../actions/timestampAction";
import Spinner from "../customComponents/Spinner";

import { ReactComponent as ModalMyProfile_img } from "./images/header_usermodal_myProfile.svg";
import { ReactComponent as ModalChangePassword_img } from "./images/header_usermodal_changePassword.svg";
import { ReactComponent as ModalLogout_img } from "./images/header_usermodal_logout.svg";

import ChangePassword from "./ChangePassword";

const Header = (props) => {
    const [userModalVisible, setUserModalVisible] = React.useState(false);

    const siteFunctionsStickyHeaderSelector = useSelector(state => state?.siteFunctions?.stickyHeader ?? true);
    const curLocation = useLocation();

    const getHeaderName = () => {
        if (curLocation.pathname === "/") return "Proxies";
        if (curLocation.pathname.startsWith("/quota-history")) return "Quota history";

        // Admin
        if (curLocation.pathname.startsWith("/admin-users")) return "[Admin] Users";
        if (curLocation.pathname.startsWith("/admin-apiTokens")) return "[Admin] API tokens";
        if (curLocation.pathname.startsWith("/admin-quotas")) return "[Admin] Quotas";
        if (curLocation.pathname.startsWith("/admin-proxyAssignments")) return "[Admin] Proxy assignments";
        if (curLocation.pathname.startsWith("/admin-smsAssignments")) return "[Admin] SMS assignments";
        if (curLocation.pathname.startsWith("/admin-devices")) return "[Admin] Connected devices";
        if (curLocation.pathname.startsWith("/admin-proxy-history")) return "[Admin] Proxy history";
        if (curLocation.pathname.startsWith("/admin-websiteFilters")) return "[Admin] Website filters";
    };

    return <div className="component__header" style={{
        position: siteFunctionsStickyHeaderSelector ? null : "initial"
    }}>
        <div className="component__header__left">
            <div className="component__header__left__hamburger">
                <div className={`component__header__left__hamburger__box`} onClick={props.toggleSidebar()}>
                    <div className={`component__header__left__hamburger__box__line component__header__left__hamburger__box__line--first`}></div>
                    <div className={`component__header__left__hamburger__box__line component__header__left__hamburger__box__line--middle`}></div>
                    <div className={`component__header__left__hamburger__box__line component__header__left__hamburger__box__line--last`}></div>
                </div>
            </div>
            {getHeaderName()}
        </div>

        <div className="component__header__right">
            <div className="component__header__right__icon" style={{backgroundImage: `url("/images/header_user.svg")`}} onClick={e => {
                e.stopPropagation();
                setUserModalVisible(u => !u);
            }}></div>
        </div>

        <UserModal visible={userModalVisible} setVisible={setUserModalVisible} />
    </div>
};

const UserModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);

    const userDataSelector = useSelector(state => state?.user ?? {});

    const curDispatch = useDispatch();

    const performLogout = () => {
        setSpinner(true);

        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            setSpinner(false);
            curDispatch(setTimestamp());
        });
    };

    const performChangePassword = (e) => {
        setSpinner(true);
        animateBox(e, <ChangePassword onChange={() => setSpinner(false)} />)
    };


    React.useEffect(() => {
        const handler = () => {
            if (props.visible) props.setVisible(false);
        };

        document.body.addEventListener("click", handler);

        return () => {
            document.removeEventListener("click", handler);
        };
    }, [props.visible]);

    return <div className={`component__header__userModal ${props.visible ? "component__header__userModal--active" : ""}`} onClick={e => {
        e.stopPropagation();
    }}>
        <div className={`component__header__userModal__spinner ${spinner ? "component__header__userModal__spinner--active" : ""}`}>
            <Spinner style={{ width: "64px", height: "64px" }} color="#6C5DD3" />
        </div>

        <div className="component__header__userModal__first">
            <img src="/images/header_usermodal_img.svg" />
            <p>{userDataSelector?.UserInfo?.Username}</p>
        </div>

        <div className="component__header__userModal__button">
            <ModalMyProfile_img />
            <p>My profile</p>
        </div>
        <div className="component__header__userModal__button" onClick={performChangePassword}>
            <ModalChangePassword_img />
            <p>Change password</p>
        </div>
        <div className="component__header__userModal__button" onClick={performLogout}>
            <ModalLogout_img />
            <p>Logout</p>
        </div>
    </div>
};

export default Header;