import React from "react";
import "./index.scss";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import animateModule from "../../modules/animateModule";

import { ReactComponent as Users_img } from "./images/sidebar_users.svg";
import { ReactComponent as ApiTokens_img } from "./images/sidebar_apiTokens.svg";
import { ReactComponent as ConnectedDevices_img } from "./images/sidebar_connectedDevices.svg";
import { ReactComponent as Proxy_img } from "./images/sidebar_proxy.svg";
import { ReactComponent as ProxyAssign_img } from "./images/sidebar_proxyAssign.svg";
import { ReactComponent as Sms_img } from "./images/sidebar_sms.svg";
import { ReactComponent as SmsAssign_img } from "./images/sidebar_smsAssign.svg";
import { ReactComponent as WebsiteFilters_img } from "./images/sidebar_websiteFilters.svg";

const Sidebar = (props) => {
    const curLocation = useLocation();
    const curNavigate = useNavigate();

    const reportFunctionsRef = React.useRef();

    const userInfoSelector = useSelector(state => state?.user?.UserInfo ?? {});

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    const [windowSize, setWindowSize] = React.useState(window.innerWidth);
    const [reportsVisible, setReportsVisible] = React.useState(false);

    const checkSidebarActive = elem => {
        if (!elem) return curLocation.pathname === "/";
        return (curLocation.pathname.startsWith(`/${elem}`));
    };

    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    })

    return <>
        <div className={`component__sidebar ${props.sidebarOpened ? 'component__sidebar--opened' : ''}`} onMouseEnter={() => {
            if(windowSize > 768){
                props.setSidebar(true)
            }}} onMouseLeave={() => {
                if(windowSize > 768){
                    props.setSidebar(false)
                }
                if (reportFunctionsRef.current) reportFunctionsRef.current?.close?.();
            }}>
            <div className="component__sidebar__logo">
                <img src="/images/logo.svg" />
                <p>Scale Proxy</p>
                <div className="component__sidebar__logo__x"  onClick={props.toggleSidebar()}>
                    <div className="component__sidebar__logo__x__line"></div>
                    <div className="component__sidebar__logo__x__line-2"></div>
                </div>
            </div>

            <div onClick={() => animateNavigate("/")} className={`component__sidebar__item ${checkSidebarActive("") ? "component__sidebar__item--active" : ""}`}>
                <Proxy_img />
                <p>Proxies</p>
            </div>
            <div onClick={() => animateNavigate("/sms")} className={`component__sidebar__item ${checkSidebarActive("sms") ? "component__sidebar__item--active" : ""}`}>
                <Sms_img />
                <p>SMS</p>
            </div>


            {userInfoSelector?.Flags?.isAdmin && <>
                <div className="component__sidebar__line"></div>
                <div className="component__sidebar__text">
                    <span style={{ opacity: props.sidebarOpened ? '1' : '0', maxWidth: props.sidebarOpened ? '70px' : '0px' }}>Admin</span>
                    <span className="component__sidebar__text--short" style={{ opacity: props.sidebarOpened ? '0' : '1', maxWidth: props.sidebarOpened ? '0px' : '70px' }}>Adm</span>
                </div>

                <div onClick={() => animateNavigate("/admin-users")} className={`component__sidebar__item ${checkSidebarActive("admin-users") ? "component__sidebar__item--active" : ""}`}>
                    <Users_img />
                    <p>All users</p>
                </div>

                <div onClick={() => animateNavigate("/admin-apiTokens")} className={`component__sidebar__item ${checkSidebarActive("admin-apiTokens") ? "component__sidebar__item--active" : ""}`}>
                    <ApiTokens_img />
                    <p>API tokens</p>
                </div>

                <div onClick={() => animateNavigate("/admin-proxyAssignments")} className={`component__sidebar__item ${checkSidebarActive("admin-proxyAssignments") ? "component__sidebar__item--active" : ""}`}>
                    <ProxyAssign_img />
                    <p>Proxy assign</p>
                </div>

                <div onClick={() => animateNavigate("/admin-smsAssignments")} className={`component__sidebar__item ${checkSidebarActive("admin-smsAssignments") ? "component__sidebar__item--active" : ""}`}>
                    <SmsAssign_img />
                    <p>SMS assign</p>
                </div>

                <div onClick={() => animateNavigate("/admin-websiteFilters")} className={`component__sidebar__item ${checkSidebarActive("admin-websiteFilters") ? "component__sidebar__item--active" : ""}`}>
                    <WebsiteFilters_img />
                    <p>Website filters</p>
                </div>

                <div onClick={() => animateNavigate("/admin-devices")} className={`component__sidebar__item ${checkSidebarActive("admin-devices") ? "component__sidebar__item--active" : ""}`}>
                    <ConnectedDevices_img />
                    <p>Connected devices</p>
                </div>
            </>}
        </div>
    </>
};

export default Sidebar;