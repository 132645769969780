import React from "react";
import "./index.scss";

import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";

import Spinner from "../../../components/customComponents/Spinner";
import Checkbox from "../../../components/customComponents/Checkbox";
import StyledInput from "../../../components/styledComponents/Input";
import StyledButton from "../../../components/styledComponents/Button";

import YesNoModal from "../../../components/modals/YesNoModal";

const AdminWebsiteFilters = () => {
    const [data, setData] = React.useState();
    const [changed, setChanged] = React.useState(false);

    const inputRef = React.useRef();

    const changeData = newData => {
        if (!data) return;
        if (data.status !== "ok") return;
        setChanged(true);
        setData({status: "ok", data: {...newData}});
    };

    const addFilter = (e) => {
        let curElem = e;

        if (!inputRef.current.value) return;
        let rgx = inputRef.current.value;
        try {
            new RegExp(rgx);
        } catch {
            animateBox(curElem, <YesNoModal
                heading="Error"
                text="Filter you are trying to add is not a valid regex!"
                buttonLeftHidden={true}
                buttonRightText={"Ok"}
                isRightButtonNormal={true}
            />);
            return;
        };
        if (data.data.Filters.includes(rgx)) return animateBox(curElem, <YesNoModal
            heading="Already exist"
            text="Filter you are trying to add already exists in the list"
            buttonLeftHidden={true}
            buttonRightText={"Ok"}
            isRightButtonNormal={true}
        />);
        changeData({
            ...data.data,
            Filters: [
                ...data.data.Filters,
                rgx
            ]
        });
        inputRef.current.value = "";
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/server/vars/get`,
            data: {
                Name: "proxy_website_filters"
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const saveData = (e) => {
        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text="You are about to save new site filters, they will be active within 60 seconds. Make sure to test them before saving!"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.disabledAll(true);
                args.spinner(true);

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/server/vars/add`,
                    data: {
                        Name: "proxy_website_filters",
                        Value: data.data,
                        createIfNotExist: false
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        args.close();
                        setChanged(false);
                        setData(null);
                        setTimeout(() => getData(), 1000);
                    } else {
                        args.errorMessage("There was an error while saving the data!");
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out!");
                }).finally(() => {
                    args.disabledAll(false);
                    args.spinner(false);
                });
            }}
        />);
    };

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="route__admin__websiteFilters">
        {data ? <>
            {data.status === "ok" ? <>
                <div className="route__admin__websiteFilters__mode">
                    <h3>Allowed protocols</h3>
                    <p>
                        <Checkbox checked={data.data.Protocols["http"]} onChange={(e) => {
                            if (data.data.Protocols["http"] === !!e) return;
                            changeData({
                                ...data.data,
                                Protocols: {...data.data.Protocols, ["http"]: !!e}
                            });
                        }} />
                        <span>HTTP</span>
                    </p>
                    <p>
                        <Checkbox checked={data.data.Protocols["https"]} onChange={(e) => {
                            if (data.data.Protocols["https"] === !!e) return;
                            changeData({
                                ...data.data,
                                Protocols: {...data.data.Protocols, ["https"]: !!e}
                            });
                        }} />
                        <span>HTTPS</span>
                    </p>
                    <p>
                        <Checkbox checked={data.data.Protocols["ws"]} onChange={(e) => {
                            if (data.data.Protocols["ws"] === !!e) return;
                            changeData({
                                ...data.data,
                                Protocols: {...data.data.Protocols, ["ws"]: !!e}
                            });
                        }} />
                        <span>WebSocket (ws)</span>
                    </p>
                    <p>
                        <Checkbox checked={data.data.Protocols["wss"]} onChange={(e) => {
                            if (data.data.Protocols["wss"] === !!e) return;
                            changeData({
                                ...data.data,
                                Protocols: {...data.data.Protocols, ["wss"]: !!e}
                            });
                        }} />
                        <span>WebSocket secure (wss)</span>
                    </p>
                    <p>
                        <Checkbox checked={data.data.Protocols["ftp"]} onChange={(e) => {
                            if (data.data.Protocols["ftp"] === !!e) return;
                            changeData({
                                ...data.data,
                                Protocols: {...data.data.Protocols, ["ftp"]: !!e}
                            });
                        }} />
                        <span>FTP</span>
                    </p>
                    <p>
                        <Checkbox checked={data.data.Protocols["sftp"]} onChange={(e) => {
                            if (data.data.Protocols["sftp"] === !!e) return;
                            changeData({
                                ...data.data,
                                Protocols: {...data.data.Protocols, ["sftp"]: !!e}
                            });
                        }} />
                        <span>SFTP</span>
                    </p>
                    <p>
                        <Checkbox checked={data.data.Protocols["tftp"]} onChange={(e) => {
                            if (data.data.Protocols["tftp"] === !!e) return;
                            changeData({
                                ...data.data,
                                Protocols: {...data.data.Protocols, ["tftp"]: !!e}
                            });
                        }} />
                        <span>TFTP</span>
                    </p>
                    <p>
                        <Checkbox checked={data.data.Protocols["smb"]} onChange={(e) => {
                            if (data.data.Protocols["smb"] === !!e) return;
                            changeData({
                                ...data.data,
                                Protocols: {...data.data.Protocols, ["smb"]: !!e}
                            });
                        }} />
                        <span>Samba (SMB)</span>
                    </p>
                </div>
                <div className="route__admin__websiteFilters__mode">
                    <h3>Filter type</h3>
                    <p>
                        <Checkbox disableCheck={true} checked={data.data.Type === "whitelist"} onClick={() => {
                            changeData({
                                ...data.data,
                                Type: "whitelist"
                            });
                        }} />
                        <span>Whitelist - allow only sites specified below</span>
                    </p>
                    <p>
                        <Checkbox disableCheck={true} checked={data.data.Type === "blacklist"} onClick={() => {
                            changeData({
                                ...data.data,
                                Type: "blacklist"
                            });
                        }} />
                        <span>Blacklist - allow ALL sites except those specified below</span>
                    </p>
                </div>

                <div className="route__admin__websiteFilters__links">
                    <h3>Filters</h3>
                    <p className="route__admin__websiteFilters__links__gray">You can use any valid RegEx</p>
                    <p className="route__admin__websiteFilters__links__gray">It takes around 60 seconds for any changes to apply</p>
                    <p className="route__admin__websiteFilters__links__gray">NOTE: Matching is done by domains and subdomains only, so "something.example.com" is going to be matched against filters. Path, port and protocol can't be filtered</p>
                    <p className="route__admin__websiteFilters__links__gray">NOTE2: To match a single domain use ^domain.com$</p>
                    <p className="route__admin__websiteFilters__links__gray">NOTE3: To match any subdomain use {`^.{1,}\\.domain.com$`}</p>

                    <div className="route__admin__websiteFilters__links__input">
                        <StyledInput ref={inputRef} placeholder="Filter" />
                        <StyledButton onClick={addFilter}>Add</StyledButton>
                        {changed && <StyledButton onClick={saveData}>Save changes</StyledButton>}
                    </div>

                    <div className="route__admin__websiteFilters__links__list">
                        {data.data.Filters.map(f => {
                            return <div className="route__admin__websiteFilters__links__list__item">
                                <span>{f}</span>
                                <StyledButton onClick={e => animateBox(e, <TestRegex regex={f} />)}>Test</StyledButton>
                                <StyledButton onClick={() => {
                                    changeData({...data.data, Filters: data.data.Filters.filter(ff => ff !== f)})
                                }}>Remove</StyledButton>
                            </div>
                        })}
                    </div>
                    <StyledButton onClick={e => animateBox(e, <TestRegex regex={data.data.Filters} />)}>Test all</StyledButton>
                </div>
            </> : <p style={{color: "rgb(238, 102, 102)"}}>There was an error while fetching data!</p>}
        </> : <Spinner color="white" />}
    </div>
};

const TestRegex = (props) => {
    const [testStatus, setTestStatus] = React.useState(null);
    const [input, setInput] = React.useState("");

    const performTest = () => {
        if (!input) return setTestStatus(null);

        try {
            if ((new RegExp(props.regex, "gmis")).test(input)) {
                setTestStatus(true);
            } else {
                setTestStatus(false);
            };
        } catch {
            setTestStatus(false);
        };
    };

    const performTestMultiple = () => {
        if (!input) return setTestStatus(null);

        let successful = 0;
        for (let item of props.regex) {
            try {
                if ((new RegExp(item, "gmis")).test(input)) {
                    successful += 1;
                };
            } catch {};
        };

        if (successful === 0) return setTestStatus(false);
        setTestStatus(successful);
    };

    React.useEffect(() => {
        if (Array.isArray(props.regex)) {
            performTestMultiple();
        } else {
            performTest();
        };
    }, [input]);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Test RegEx</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>Enter the text below to test against the regex</p>
                <input value={input} onChange={e => setInput(e?.currentTarget.value)} type="text" placeholder="example.com, google.com..." />
            </div>

            <div className="genericModal__wrap__input">
                <p>Status: {testStatus === null ? "Waiting" : <>
                    {testStatus ? (testStatus === true ? <span style={{color: "#6e6"}}>Matched</span> : <>
                        {testStatus === props.regex.length ? <span style={{color: "#6e6"}}>All matched</span> : <span style={{color: "#6e6"}}>{testStatus} matched</span>}
                    </>) : <span style={{color: "#e66"}}>No match</span>}
                </>}</p>
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
            </div>
        </div>
    </div>
};

export default AdminWebsiteFilters;