import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { convertBytes } from "../../../modules/miscModule";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import useDefer from "../../../modules/hooks/useDefer";
import animateModule from "../../../modules/animateModule";
import { countries } from "../../../modules/countryModules";
import { animateBox } from "../../../modules/componentAnimation";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import YesNoModal from "../../../components/modals/YesNoModal";

const UserProxies = () => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    
    const timestampRef = React.useRef();
    const curOnScreen = useOnScreen();
    const curDefer = useDefer();
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/proxyAssigns/getProxies`,
            data: {
                limit: 20,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);

            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = (ts) => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (!canPaginate) return;
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/proxyAssigns/getProxies`,
            data: {
                limit: 20,
                offset: data.data.length
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;

            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    const getLastUsedDate = lastUsed => {
        let l = moment(lastUsed);
        if (l.get("year") === 1980) return "Never";

        return l.fromNow();
    };

    const getQutaData = quota => {
        if (!quota) return <span style={{color: "gray"}}>N/A</span>;

        let qb = window.BigInt(quota.QuotaBytes);
        let ub = window.BigInt(quota.UsedBytes);

        let color = "#6aff81";
        if (qb <= ub) {
            color = "#ff8d8d";
        } else if ((ub * 100n) / qb >= 80n) {
            color = "#fbff8b";
        };

        let finalPercent = (ub * 100n) / qb;
        let tmp1 = convertBytes(ub);
        let tmp2 = convertBytes(qb);

        return <div className="route__user__proxies__quotaLine">
            <span style={{color: color}}>{tmp1} / {tmp2}</span>
            <span className="route__user__proxies__quotaLine__line">
                <span style={{width: `${finalPercent}%`, backgroundColor: color}}></span>
            </span>
        </div>;
    };

    const viewText = (e, heading, text) => {
        animateBox(e, <YesNoModal
            heading={heading}
            text={text}
            buttonRightText="Ok"
            isRightButtonNormal={true}
            isLeftButtonNormal={true}
            buttonLeftText={"Copy"}
            buttonLeftCallback={(args) => {
                args.close();
                try { navigator.clipboard.writeText(text); } catch {};
            }}
        />);
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        curDefer(() => {
            let ts = Date.now();
            timestampRef.current = ts;
            continueData(ts);
        }, 500);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, []);

    return <div className="route__user__proxies">
        <FilteredCustomTable
            style={{width: "100%", columnGap: "50px"}}
            accent="#6C5DD3"
            theme="dark"
            headers={["Username", "Password", "Country", "Type", "Server", "Last used", "Quota", ""]}
            customColumns={["100px", "100px", "auto", "auto", "auto", "auto", "auto", "1fr"]}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "Error while fetching proxies!"}]];

                let out = [];
                let i = 0;
                for (let item of data.data) {
                    let curCountry = countries.find(c => c.code === item.Country);
                    if (curCountry) curCountry = curCountry.name; else curCountry = "Any country";
                    i++;
                    out.push([
                        {keyID: item.Password, type: "text", text: item.Username},
                        {keyID: item.Password, type: "text", text: <PasswordField password={item.Password} />, style:{
                            color: "gray",
                            maxWidth: "100%",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            cursor: "pointer"
                        }, onClick: e => viewText(e, "Password", item.Password)},
                        {keyID: item.Password, type: "text", text: curCountry},
                        {keyID: item.Password, type: "text", text: item.Type},
                        {keyID: item.Password, type: "text", text: item.BaseURL, style:{
                            color: "gray",
                            maxWidth: "100%",
                            width: "100px",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            cursor: "pointer"
                        }, onClick: e => viewText(e, "Server", item.BaseURL)},
                        {keyID: item.Password, type: "text", text: getLastUsedDate(item.LastUsed)},
                        {keyID: item.Password, type: "text", text: getQutaData(item.Quota)},
                        {keyID: item.Password, type: "group", group: [
                            {keyID: item.Password, type: "button", text: "Get URL", onClick: e => {
                                animateBox(e, <YesNoModal
                                    heading="Proxy URL"
                                    text={<span style={{userSelect: "all"}}>{item.FullURL}</span>}
                                    buttonRightText="Ok"
                                    isRightButtonNormal={true}
                                    isLeftButtonNormal={true}
                                    buttonLeftText={"Copy"}
                                    buttonLeftCallback={(args) => {
                                        args.close();
                                        try { navigator.clipboard.writeText(item.FullURL); } catch {};
                                    }}
                                />)
                            }},
                            {keyID: item.Password, type: "button", text: "Get IP change URL", style:{marginLeft: "10px"}, onClick: e => {
                                animateBox(e, <YesNoModal
                                    heading="IP Change URL"
                                    text={<span style={{userSelect: "all"}}>{item.ChangeURL}</span>}
                                    buttonRightText="Ok"
                                    isRightButtonNormal={true}
                                    isLeftButtonNormal={true}
                                    buttonLeftText={"Copy"}
                                    buttonLeftCallback={(args) => {
                                        args.close();
                                        try { navigator.clipboard.writeText(item.ChangeURL); } catch {};
                                    }}
                                />)
                            }},
                            (item.Quota ? {keyID: item.Password, type: "button", text: "Quota usage history", style:{marginLeft: "10px"}, onClick: e => {
                                animateNavigate(`/quota-history/${item.Quota.ID}`);
                            }} : null)
                        ].filter(t => t)},

                        (item.ID ? {keyID: item.Password, type: "groupNewline", group: [
                            {keyID: item.ID, type: "text", text: <>
                                <span style={{color: "gray"}}>ID: </span>
                                <span>{item.ID}</span>
                            </>}
                        ]} : null),
                        (item.Description ? {keyID: item.Password, type: "groupNewline", group: [
                            {keyID: item.Password, type: "text", text: <>
                                <span style={{color: "gray"}}>Description: </span>
                                <span>{item.Description}</span>
                            </>}
                        ]} : null)
                    ].filter(f => f));
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                return out;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{width: "1px", height: "1px", opacity: 0}}></div>}
    </div>
};

const PasswordField = props => {
    const [passwordVisible, setPasswordVisible] = React.useState(false);

    return <span
        style={{
            display: "block",
            maxWidth: "200px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden"
        }}
        onMouseOver={e => setPasswordVisible(true)}
        onMouseLeave={e => setPasswordVisible(false)}
    >{passwordVisible ? props.password : "*".repeat(props.password.length)}</span>
};

export default UserProxies;