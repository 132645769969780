export const addHeaderRefreshAction = (fn) => {
    if (typeof(fn) !== "function") return;

    return {
        type: "SITE_FUNCTIONS_ADD_HEADER_REFRESH",
        payload: fn
    };
};
export const removeHeaderRefreshAction = fn => {
    if (typeof(fn) !== "function") return;

    return {
        type: "SITE_FUNCTIONS_REMOVE_HEADER_REFRESH",
        payload: fn
    };
};

export const updateTrackingProfilesTimestamp = () => {
    return {
        type: "SITE_FUNCTIONS_UPDATE_TRACKING_TIMESTAMP"
    };
};

export const updateStickyHeader = newState => {
    return {
        type: "SITE_FUNCTIONS_UPDATE_STICKY_HEADER",
        payload: !!newState
    };
};