import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";

import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import { countries } from "../../../modules/countryModules";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import ButtonWithDropdown from "../../../components/customComponents/ButtonWithDropdown";
import Checkbox from "../../../components/customComponents/Checkbox";
import Spinner from "../../../components/customComponents/Spinner";
import Button from '../../../components/customComponents/Button';
import Dropdown from "../../../components/customComponents/Dropdown";
import FilterPanel from '../../../components/customComponents/FilterPanelNew';

import YesNoModal from "../../../components/modals/YesNoModal";

const Users = (props) => {
  const [data, setData] = React.useState();
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [filters, setFilters] = React.useState(false);

  const checkboxFunctionsRef = React.useRef();

  const curUserSelector = useSelector(state => state?.user?.UserInfo ?? {});

  const getUsers = () => {
    if (!curUserSelector?.Flags?.isAdmin) return setData(backendModule.genericError);

    checkboxFunctionsRef.current?.reset();
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/${(curUserSelector?.Flags?.isAdmin && !props.teamLead) ? "getAllUsers" : "getAllUsersTeamLead"}`,
      data: {
        ...(!props.teamLead ? {} : {
          excludeSelf: true
        }),
        filters: filters
      },
      ...backendModule.axiosConfig
    }).then(res => {
      setData(res.data);
    }).catch(() => {
      setData(backendModule.genericError);
    });
  };

  const removeUser = (e, ids) => {
    if (ids.length === 0) return;

    if (ids.includes(curUserSelector?.ID)) return animateBox(e, <YesNoModal
      heading="Error"
      text="You can't remove your own account!"
      buttonLeftHidden={true}
      buttonRightText="Ok"
      isRightButtonNormal={true}
    />);

    animateBox(e, <YesNoModal
      heading="Are you sure?"
      text={["You are about to remove ", <span style={{ color: "#6C5DD3" }}>{ids.length} users</span>, ", this action is irreversible!"]}
      isRightButtonNormal={true}
      buttonRightCallback={async (args) => {
        args.disabledAll(true);
        args.spinner(true);

        let hadErrors = false;
        for (let item of ids) {
          await axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/removeUser`,
            data: {
              ID: item
            },
            ...backendModule.axiosConfig
          }).then(res => {
            if (res.data.status !== "ok") {
              hadErrors = true;
            };
          }).catch(() => {
            hadErrors = true;
          });
        };

        args.disabledAll(true);
        args.spinner(false);
        args.close();
        getUsers();

        if (hadErrors) {
          let tmp = document.querySelector(".route__admin__users__btns__right__btn");
          if (!tmp) return;

          animateBox({ currentTarget: tmp }, <YesNoModal
            heading="Error"
            text="There was an error while removing one or more users."
            buttonLeftHidden={true}
            buttonRightText="Ok"
          />);
        };
      }}
    />);
  };

  const editUser = (e, id) => {
    if (!data) return;
    if (data.status === "error") return;
    if (!curUserSelector) return;

    let curUser = data.data.find(d => d.ID === id);
    if (!curUser) return;

    let countries;
    if(curUser?.Countries.length){
      countries = curUser.Countries
    }else{
      countries = []
    }
    // if (curUserSelector?.ID === id) return animateBox(e, <YesNoModal
    //     heading="Error"
    //     text="You can't edit your own account!"
    //     buttonLeftHidden={true}
    //     buttonRightText="Ok"
    //     isRightButtonNormal={true}
    // />);

    animateBox(e, <AddUser onChange={getUsers} edit={curUser} userCountries={countries}  />);
  };

  React.useEffect(() => {
    getUsers();
  }, [filters]);

  return <div className="route__admin__users">
    <div className="route__admin__users__btns">
      <div className="route__admin__users__btns__left">
        <div className={`route__admin__users__btns__left__selected ${selectedUsers.length > 0 ? "route__admin__users__btns__left__selected--active" : ""}`}>
          {`${selectedUsers.length} users selected`}
          <div
            className="route__admin__users__btns__left__selected__btn"
            style={{ backgroundImage: `url("/images/icon_close.svg")` }}
            onClick={() => checkboxFunctionsRef.current?.reset()}
          ></div>
        </div>
      </div>

      <div className="route__admin__users__btns__right">
        {selectedUsers.length > 0 && <ButtonWithDropdown
          image="/images/icon_edit.svg"
          value="More actions"
          data={[
            (selectedUsers.length === 1 && { name: "Edit", onClick: e => editUser(e, selectedUsers[0]) }),
            { name: `Remove ${selectedUsers.length} users`, onClick: (e) => removeUser(e, selectedUsers) }
          ]}
        />}

        {selectedUsers.length === 0 && <div className="route__admin__users__btns__right__btn" onClick={e => {
          animateBox(e, <AddUser onChange={() => getUsers()} />);
        }}>
          <img src="./images/icon_close.svg" style={{ transform: "rotate(45deg)" }} />
          <span>Add user</span>
        </div>}
      </div>
    </div>

    <FilterPanel
      theme="dark"
      accent="rgb(62, 87, 166)"
      filters={[
        { name: "createdAt", friendlyName: "Date created", type: "date" },
        { name: "ID", friendlyName: "ID", type: "string" },
        { name: "Username", friendlyName: "Username", type: "string" },
        { name: "Email", friendlyName: "Email", type: "string" },
      ]}
      filterCB={f => {
        setFilters(f);
      }}
    />

    <div className="route__admin__users__tableWrap">
      <FilteredCustomTable
        key="admin-users-table"
        checkboxCB={(data?.status === "ok" && data?.data?.length > 0) ? setSelectedUsers : false}
        checkboxFunctions={cf => checkboxFunctionsRef.current = cf()}
        accent="#6C5DD3"
        theme="dark"
        headers={curUserSelector.Flags.isAdmin ? ["ID", "Username", "Email", "Admin"] : ["Username", "Email"]}
        data={(() => {
          let out = [];

          if (!data) {
            out.push([{ keyID: "noData-spinner", type: "spinner", color: "white" }]);
          } else {
            if (data.status === "ok") {
              for (let item of data.data) {
                if (curUserSelector.Flags.isAdmin) {
                  out.push([
                    { keyID: String(item.ID), type: "text", text: item.ID },
                    { keyID: String(item.ID), type: "text", text: item.Username },
                    { keyID: String(item.ID), type: "text", text: item.Email },
                    {
                      keyID: String(item.ID), type: "custom", data: item.Flags?.isAdmin ? <span style={{ color: "#6e6", textAlign: 'center' }}>Yes</span>
                        : <span style={{ color: "#e66", textAlign: 'center' }}>No</span>
                    },
                    {
                      keyID: String(item.ID), type: "groupNewline", group: [
                        {
                          keyID: String(item.ID), type: 'custom', data:<div className="users__countries">
                            {
                              item.Countries.length > 0 ? item.Countries.map(elem => {
                                return <p><img src={`./images/flags/${(elem.code).toLowerCase()}.png`} /> {elem.code}</p>
                              }) : null
                            }
                          </div>
                        }
                      ]
                    }
                  ]);
                } else {
                  out.push([
                    { keyID: String(item.ID), type: "text", text: item.Username },
                    { keyID: String(item.ID), type: "text", text: item.Email }
                  ]);
                };
              };
            } else {
              out.push([{ keyID: "noData-error", type: "text", text: "An error occured!" }]);
            };
          };

          if (out.length === 0) {
            out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show..." }]);
          };
          return out;
        })()}
      />
    </div>


  </div>
};

const AddUser = props => {
  const [infoP, setInfoP] = React.useState({
    hadError: false,
    error: "",
    inputs: []
  });
  const [flags, setFlags] = React.useState({});
  const [spinner, setSpinner] = React.useState(false);

  const [userCountries, setUserCountries] = React.useState(props.userCountries ? props.userCountries : [])

  const curUserSelector = useSelector(state => state?.user?.UserInfo ?? {});

  const usernameRef = React.useRef();
  const passwordRef = React.useRef();
  const emailRef = React.useRef();

  const onClose = (force = false) => {
    if (spinner && !force) return;

    props.onClose();
  };

  const addUser = () => {
    setInfoP(i => { return { ...i, hadError: false, inputs: [] } });

    let data = {
      username: usernameRef.current.value,
      password: passwordRef.current.value,
      email: emailRef.current.value,
      countries: userCountries, 
      flags
    };

    if (!data.username) return setInfoP(i => { return { ...i, hadError: true, inputs: ["username"], error: "Username can't be empty" } });

    if (!props.edit && !data.password) return setInfoP(i => { return { ...i, hadError: true, inputs: ["password"], error: "Password can't be empty" } });

    if (data.password && (data.password.length < 8 || data.password.length > 64)) return setInfoP(i => { return { ...i, hadError: true, inputs: ["password"], error: "Password must be between 8 and 64 characters" } });

    if (props.edit) data["id"] = props.edit.ID;

    if (!curUserSelector?.Flags?.isAdmin) data["flags"] = {
      ...flags,
      isAdmin: false,
      isTeamLeader: false
    };

    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/${props.edit ? "editUser" : "addUser"}`,
      data,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        if (props.onChange) props.onChange();
        return onClose(true);
      } else {
        return setInfoP(i => { return { ...i, hadError: true, inputs: [], error: `Error while ${props.edit ? "editing" : "creating"} user` } });
      };
    }).catch(() => {
      return setInfoP(i => { return { ...i, hadError: true, inputs: [], error: "Server timed out" } });
    }).finally(() => {
      setSpinner(false);
    });
  };

  return <div className="route__admin__users__add">
    <div className="route__admin__users__add__wrap">
      <div className="route__admin__users__add__wrap__head">
        <div className="route__admin__users__add__wrap__head__left">{props.edit ? "Edit" : "Add"} user</div>
        <div className="route__admin__users__add__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
      </div>

      <div className={`route__admin__users__add__wrap__input ${infoP.inputs.includes("username") ? "route__admin__users__add__wrap__input--error" : ""}`}>
        <p>Username</p>
        <input ref={usernameRef} type="text" placeholder="Username" defaultValue={props.edit?.Username} />
      </div>
      <div className={`route__admin__users__add__wrap__input ${infoP.inputs.includes("password") ? "route__admin__users__add__wrap__input--error" : ""}`}>
        <p>{props.edit ? `Password (optional)` : "Password"}</p>
        <input ref={passwordRef} type="password" placeholder="Password" />
      </div>
      <div className={`route__admin__users__add__wrap__input ${infoP.inputs.includes("email") ? "route__admin__users__add__wrap__input--error" : ""}`}>
        <p>{props.edit ? `Email (optional)` : "Email"}</p>
        <input ref={emailRef} type="text" placeholder="Email" />
      </div>
      <div className="route__admin__users__add__wrap__checkbox">
        <Checkbox defaultValue={props.edit?.Flags?.isAdmin ?? false} onChange={e => {
          if (flags["isAdmin"] !== e) setFlags(flg => {
            return { ...flg, isAdmin: e };
          });
        }} />
        <span>This user is Admin</span>
      </div>
      <div className="route__admin__users__add__wrap__buttons" style={{justifyContent: 'flex-start', marginBottom: '20px'}}>
        <p className="route__admin__users__add__wrap__buttons__btn" onClick={e => {
          animateBox(e, <CountriesSelectionModal setUserCountries={setUserCountries} userCountries={userCountries} />);
        }}>{props.edit ? "Edit" : "Add"} Countries</p>
      </div>
      
      <div className="route__admin__users__add__wrap__countryList">
        {userCountries && userCountries.map(item => {
          return <p><img src={`./images/flags/${(item.code).toLowerCase()}.png`} /> {item.name}</p>
        })}
      </div>
      <div className="route__admin__users__add__wrap__buttons">
        <div className="route__admin__users__add__wrap__buttons__btn route__admin__users__add__wrap__buttons__btn--secondary" onClick={onClose}>Cancel</div>
        <div className="route__admin__users__add__wrap__buttons__btn" onClick={addUser}>
          {spinner ? <Spinner style={{ width: "24px", height: "24px" }} color="white" /> : "Save"}
        </div>
      </div>

      <p className="route__admin__users__add__wrap__infoP" style={{
        opacity: infoP.hadError ? 1 : 0
      }}>{infoP.error}</p>
    </div>
  </div>
};

const CountriesSelectionModal = (props) => {

  const [spinner, setSpinner] = React.useState(false);
  const [country, setCountry] = React.useState('');

  const [selectedCountries, setSelectedCountries] = React.useState(props.userCountries ? props.userCountries : []);

  const onClose = (force = false) => {
    if (spinner && !force) return;
    props.onClose();
  };


  return <div className="country__selection__modal">
    <div className="country__selection__modal__wrapper">
      <div className="country__selection__modal__wrapper__head">
        <div className="country__selection__modal__wrapper__head__left">{props.edit ? "Edit" : "Add"} Countries</div>
        <div className="country__selection__modal__wrapper__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
      </div>
      <div className="country__selection__modal__wrapper__selection">
        <Dropdown theme="dark" accent={'dark'} selected={country} data={countries.map((country) => {
          return {
            name: country.name,
            code: country.code
          }
        })} onChange={e => {setCountry({code: e?.code, name: e?.name})}} />
        <div className="country__selection__modal__wrapper__selection__add" onClick={() => setSelectedCountries([...selectedCountries, {name: country.name, code: country.code}])}>Add</div>
      </div>
      <div className="country__selection__modal__wrapper__items">
        {selectedCountries && selectedCountries.map(elem => {
          return <div className="country__selection__modal__wrapper__items__item">
            {elem.code && elem.name && <p><img src={`./images/flags/${(elem.code).toLowerCase()}.png`} /> {elem.code} - {elem.name}
            <span onClick={() => {
              let temp = selectedCountries;
              const filteredArray = temp.filter(item => item.code !== elem.code)
              setSelectedCountries(filteredArray);
            }}>Remove</span></p>}
          </div>
        })}
      </div>

      <div className="country__selection__modal__wrapper__bottom">
        <div className="country__selection__modal__wrapper__bottom__button" onClick={() => {props.setUserCountries(selectedCountries); props.onClose()}}>Save Countries</div>
      </div>
      
    </div>
  </div>
}


export default Users