import React from "react";
import {HashRouter as Router, Routes, Route} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";
import { animateBox } from "./modules/componentAnimation";

import Header from "./components/Header";
import ContentWrapper from "./components/ContentWrapper";

import LandingPage from "./routes/LandingPage";
import Login from "./routes/Login";
import Dashboard from "./routes/Dashboard";
import Sidebar from "./components/Sidebar";

import UserProxies from "./routes/user/Proxies";
import UserSMS from "./routes/user/SMS";
import UserQuotaHistory from "./routes/user/QuotaHistory";

import Users from "./routes/admin/Users";
import AdminApiTokens from "./routes/admin/ApiTokens";
import ProxyAssignments from "./routes/admin/ProxyAssignments";
import ProxySMSAssignments from "./routes/admin/ProxySMSAssignments";
import AdminDevices, { AdminSingleDevice } from "./routes/admin/Devices";
import AdminProxyHistory from "./routes/admin/ProxyHistory";
import AdminWebsiteFilters from "./routes/admin/WebsiteFilters";

import * as backendModule from "./modules/backendModule";
import * as userActions from "./actions/userActions";
import * as serverStatusActions from "./actions/serverStatusActions";

const App = () => {

  const userIDSelector = useSelector(state => state?.user?.UserInfo?.ID ?? null);
  const userSelector = useSelector(state => state?.user?.UserInfo ?? null);
  const serverStatusSelector = useSelector(state => state.serverStatus?.status);
  const siteFunctionsStickyHeaderSelector = useSelector(state => state?.siteFunctions?.stickyHeader ?? true);
  const timestampSelector = useSelector(state => state.timestamp);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);

  const mainDispatch = useDispatch();

  const checkLogin = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      mainDispatch(serverStatusActions.setServerStatus(true));
      if (res.data.status === "ok") {
        mainDispatch(userActions.updateUser(res.data.data));
      }
      else {
        mainDispatch(userActions.updateUser(null));
      }
    }).catch(() => {
      mainDispatch(serverStatusActions.setServerStatus(false));
    })
  };

  React.useEffect(() => {
    const intervalLength = serverStatusSelector ? 10000 : 2000;
    checkLogin();
    let interval = setInterval(checkLogin, intervalLength);

    return () => clearInterval(interval);
  }, [timestampSelector, userIDSelector, serverStatusSelector]);

  const toggleSidebar = () => {
    setSidebarOpened(d => !d);
  }

  return <Router>
    {
      userIDSelector ? <>
      <Sidebar sidebarOpened={sidebarOpened}
      toggleSidebar={() => toggleSidebar} sidebar={sidebarOpened} setSidebar={setSidebarOpened} />
      <ContentWrapper>
        <Header toggleSidebar={() => toggleSidebar} />
        <div className={`root__content ${siteFunctionsStickyHeaderSelector ? "" : "root__content--noSticky"}`}>
        <Routes>

          <Route path="/" element={<UserProxies />} />
          <Route path="/sms" element={<UserSMS />} />
          <Route path="/quota-history/:id" element={<UserQuotaHistory />} />
          {userSelector?.Flags?.isAdmin && <>
              <Route path="/admin-users" element={<Users ey="admin-users-admin" />} />
              <Route path="/admin-apiTokens" element={<AdminApiTokens />} />
              <Route path="/admin-proxyAssignments" element={<ProxyAssignments />} />
              <Route path="/admin-smsAssignments" element={<ProxySMSAssignments />} />
              <Route path="/admin-websiteFilters" element={<AdminWebsiteFilters />} />
              <Route path="/admin-devices" element={<AdminDevices />} />
              <Route path="/admin-devices/:id" element={<AdminSingleDevice />} />
              <Route path="/admin-proxy-history/:id" element={<AdminProxyHistory />} />
            </>
          }
          
        </Routes>
        </div>
      </ContentWrapper>
      </>
      :<>
      <ContentWrapper>
        <Routes>
  
          <Route path="/" element={<Login />} />
          
        </Routes>
      </ContentWrapper>
      </>
    }
    
  </Router>
};

export default App;
