import React from "react";
import "./index.scss";

import propTypes from "prop-types";

const StyledInput = React.forwardRef((props, ref) => {
    let out = {};
    let style = {
        ...(props.style ?? {})
    };

    if (props.alternateStyle) {
        style = {
            ...style,
            backgroundColor: "#2C2E38",
            borderColor: "#373A43"
        }
    };
    if (props.isError) out["data-error"] = 1;

    let finalProps = {...props};
    delete finalProps["alternateStyle"];
    delete finalProps["isError"];

    return <input ref={ref} {...finalProps} {...out} style={{
        ...finalProps.style,
        ...style
    }} className={`styledComponent__input ${finalProps.className ? ` ${finalProps.className}` : ""}`} />
});

export default StyledInput;

StyledInput.propTypes = {
    isError: propTypes.bool,
    alternateStyle: propTypes.bool
};