import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import * as backendModule from "../../../modules/backendModule";
import { useParams } from "react-router-dom";
import { convertBytes } from "../../../modules/miscModule";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import useDefer from "../../../modules/hooks/useDefer";

import { FilteredCustomTable } from "../../../components/customComponents/Table";

const UserQuotaHistory = () => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false); 

    const timestampRef = React.useRef();
    const curParmas = useParams();
    const curOnScreen = useOnScreen();
    const curDefer = useDefer();

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/quotas/getQuotaHistory`,
            data: {
                QuotaID: curParmas?.id,
                limit: 20,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);
            
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = (ts) => {
        if (!canPaginate) return;
        if (!data) return;
        if (data.status !== "ok") return;
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/quotas/getQuotaHistory`,
            data: {
                QuotaID: curParmas?.id,
                limit: 20,
                offset: data.data.length
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => {
            continueData(ts);
        }, 500);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, []);

    return <div className="route__user__quotaHistory">
        <FilteredCustomTable
            accent="#6C5DD3"
            theme="dark"
            headers={["Date", "Sent", "Received"]}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "Error while fetching data"}]];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.Date, type: "text", text: moment(item.Date).toDate().toLocaleDateString()},
                        {keyID: item.Date, type: "text", text: convertBytes(item.Sent)},
                        {keyID: item.Date, type: "text", text: convertBytes(item.Received)}
                    ])
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                return out;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{width: "1px", height: "1px", opacity: 0}}></div>}
    </div>
};

export default UserQuotaHistory;