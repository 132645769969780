import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";

import * as backendModule from "../../../modules/backendModule";
import { convertBytes } from "../../../modules/miscModule";
import httpStatusCodesModule from "../../../modules/httpStatusCodesModule";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import useDefer from "../../../modules/hooks/useDefer";
import { animateBox } from "../../../modules/componentAnimation";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import FilterBySearch from "../../../components/filters/FilterBySearch";

const AdminProxyHistory = () => {
    const [data, setData] = React.useState();
    const [search, setSearch] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);

    const timestampRef = React.useRef();
    const curOnScreen = useOnScreen();
    const curDefer = useDefer();
    const curParams = useParams();

    const parseSearch = () => {
        if (!search) return [];

        return [{or: [
            {name: "Url", op: "like", value: search}
        ]}];
    };

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/proxyAssigns/visits`,
            data: {
                AssignID: curParams?.id,
                limit: 20,
                offset: 0,
                filters: parseSearch()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;

            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = (ts) => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/proxyAssigns/visits`,
            data: {
                AssignID: curParams?.id,
                limit: 20,
                offset: 0,
                filters: [
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)},
                    {name: "createdAt", op: "dleq", value: data.data[data.data.length-1].createdAt},
                    ...parseSearch()
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;

            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);

                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    const getStatusColor = statusCode => {
        statusCode = Number(statusCode);

        if (statusCode >= 200 && statusCode <= 299) return "#63ec63"
        if (statusCode >= 300 && statusCode <= 399) return "rgb(69, 202, 242)";
        if (statusCode >= 400 && statusCode <= 499) return "rgb(244, 222, 104)";
        if ((statusCode >= 500 && statusCode <= 599) || statusCode === 0) return "#f27575";

        return "#fff";
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => {
            continueData(ts);
        }, 500);
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, [search]);

    return <div className="route__admin__proxyHistory">
        <FilterBySearch onChange={e => setSearch(e)} />
        <br />
        <FilteredCustomTable
            accent="#6C5DD3"
            theme="dark"
            headers={["Date", "Status", "URL"]}
            customColumns={["auto", "auto", "1fr"]}
            style={{columnGap: "20px"}}
            data={(()=>{
                if (!data) return [{columns: [{keyID: "noData-spinner", type: "spinner", color: "white"}]}];
                if (data.status === "error") return [{columns: [{keyID: "noData-error", type: "custom", data: "Error while fetching data"}]}];

                let out = [];
                for (let item of data.data) {
                    out.push({
                        events: {
                            onMouseOver: (e) => {
                                let tmp = e.target;
                                if (!tmp.classList.contains("customComponents__table__data")) return;

                                for (let item of [...tmp.parentNode.childNodes]) item.style.backgroundColor = null;

                                tmp.style.backgroundColor = "rgb(55, 51, 81)";
                            },
                            onMouseLeave: (e) => {
                                let tmp = e.target;
                                if (!tmp.classList.contains("customComponents__table__data")) return;
                                tmp.style.backgroundColor = null;
                            },
                            onClick: e => animateBox(e, <HistorySingleItem item={item} />)
                        },
                        style: {transition: "background-color 0.3s ease", cursor: "pointer"},
                        columns: [
                            {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleString()},
                            {keyID: item.ID, type: "text", text: item.Status || "No response", style: {color: getStatusColor(item.Status)}},
                            {keyID: item.ID, type: "text", text: <span>
                                <span style={{color: "rgb(69, 202, 242)"}}>[{item.Method}]</span>&nbsp;
                                <span>{item.Url}</span>
                            </span>}
                        ]
                    })
                };

                if (out.length === 0) out.push({columns: [{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]});
                return out;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{width: "1px", height: "1px", opacity: 0}}></div>}
    </div>
};

const HistorySingleItem = props => {
    const [activeTab, setActiveTab] = React.useState(1);
    const [fullScreen, setFullScreen] = React.useState(false);

    const wrapRef = React.useRef();

    const onClose = () => {
        if (props.onChange) props.onChange();

        if (wrapRef.current) {
            wrapRef.current.animate([
                {right: getComputedStyle(wrapRef.current).right},
                {right: "-100%"}
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    const getStatusColor = statusCode => {
        statusCode = Number(statusCode);

        if (statusCode >= 200 && statusCode <= 299) return "rgb(15, 119, 15)"
        if (statusCode >= 300 && statusCode <= 399) return "rgb(26, 115, 142)";
        if (statusCode >= 400 && statusCode <= 499) return "rgb(128, 114, 59)";
        if ((statusCode >= 500 && statusCode <= 599) || statusCode === 0) return "rgb(166, 2, 2)";

        return "#fff";
    };

    const getResponseTimeColor = () => {
        if (!props.item.ResponseTime) return "rgb(26, 115, 142)";
        if (props.item.ResponseTime < 1000) return "rgb(15, 119, 15)";
        if (props.item.ResponseTime < 5000) return "rgb(26, 115, 142)";
        return "rgb(166, 2, 2)";
    };

    const getFullURL = () => {
        try {
            let tmp = new URL(props.item.Url);

            for (let key of Object.keys(props.item.Query)) {
                tmp.searchParams.set(key, props.item.Query[key]);
            };

            return tmp.toString();
        } catch {
            return "";
        };
    };

    const checkPreviewResponse = () => {
        if (!props.item.ResponseBody) return false;
        if (!props.item.ResponseBody["Type"] || !props.item.ResponseBody["Data"]) return false;
        let cType = String(props.item.ResponseBody["Type"]);
        if (!cType) return false;

        if (cType.startsWith("text/html")) return "HTML";
        if (cType.startsWith("application/json")) return "JSON";

        return false;
    };

    const createPreview = () => {
        let cType = checkPreviewResponse();
        if (!cType) return null;

        if (cType === "HTML") {
            return <iframe src={"data:text/html,"+encodeURIComponent(props.item.ResponseBody["Data"])}></iframe>
        };
        if (cType === "JSON") {
            return <HighlightedJSON data={props.item.ResponseBody["Data"]} />
        };
    };

    const timeToString = () => {
        if (!props.item.ResponseTime) return "-";

        let totalMiliseconds = Number(props.item.ResponseTime);
        if (isNaN(totalMiliseconds)) return "-";

        let [ms, s, m, h] = [0, 0, 0, 0];
        while (totalMiliseconds > 1000) {
            s += 1;
            totalMiliseconds -= 1000;

            if (s >= 60) {
                m += 1;
                s -= 60;
            };
            if (m >= 60) {
                h += 1;
                m -= 60;
            };
        };
        ms = totalMiliseconds;
        if (ms < 0) ms = 0;

        let finalArr = [];
        finalArr.unshift(`${ms}ms`);
        if (!s && !m && !h) return finalArr.join(" ");

        finalArr.unshift(`${s}s`);
        if (!m && !h) return finalArr.join(" ");

        finalArr.unshift(`${m}m`);
        if (!h) return finalArr.join(" ");

        finalArr.unshift(`${h}h`);
        return finalArr.join(" ");
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            {right: getComputedStyle(wrapRef.current).right},
            {right: 0}
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    return <div className="route__admin__proxyHistory__singleItem" onClick={() => onClose()}>
        <div className="route__admin__proxyHistory__singleItem__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()} style={{
            width: fullScreen ? "100%" : null
        }}>
            <div className="route__admin__proxyHistory__singleItem__wrap__top">
                <div className="route__admin__proxyHistory__singleItem__wrap__top__left">
                    <span>History</span>
                    <div className="route__admin__proxyHistory__singleItem__wrap__top__left__btn" onClick={() => setFullScreen(f => !f)}>{fullScreen ? "Shrink" : "Expand"}</div>
                </div>
                <div className="route__admin__proxyHistory__singleItem__wrap__top__right">
                    <img src="/images/icon_close.svg" onClick={() => onClose()} />
                </div>
            </div>

            <div className="route__admin__proxyHistory__singleItem__wrap__content">

                <p className="route__admin__proxyHistory__singleItem__wrap__content__text route__admin__proxyHistory__singleItem__wrap__content__text--code">
                    <span>{props.item.Method}</span>
                    <span>{props.item.Url}</span>
                </p>
                <p className="route__admin__proxyHistory__singleItem__wrap__content__text route__admin__proxyHistory__singleItem__wrap__content__text--response">
                    <span>Time</span>
                    <span style={{backgroundColor: getResponseTimeColor()}}>{timeToString()}</span>
                </p>
                <p className="route__admin__proxyHistory__singleItem__wrap__content__text route__admin__proxyHistory__singleItem__wrap__content__text--statusCode">
                    <span>Status</span>
                    <span>
                        <span style={{backgroundColor: getStatusColor(props.item.Status)}}>{props.item.Status}</span>
                        <span>{httpStatusCodesModule[String(props.item.Status)]?.message || httpStatusCodesModule[String(props.item.Status).charAt(0) + "xx"]?.message}</span>
                    </span>
                    <span>{httpStatusCodesModule[String(props.item.Status)]?.description || httpStatusCodesModule[String(props.item.Status).charAt(0) + "xx"]?.description}</span>
                </p>
                <div className="route__admin__proxyHistory__singleItem__wrap__content__line"></div>

                <div className="route__admin__proxyHistory__singleItem__wrap__content__tabs">
                    <div className={`route__admin__proxyHistory__singleItem__wrap__content__tabs__tab ${activeTab === 1 ? "route__admin__proxyHistory__singleItem__wrap__content__tabs__tab--active" : ""}`} onClick={() => setActiveTab(1)}>Request</div>
                    {props.item.Status ? <div className={`route__admin__proxyHistory__singleItem__wrap__content__tabs__tab ${activeTab === 2 ? "route__admin__proxyHistory__singleItem__wrap__content__tabs__tab--active" : ""}`} onClick={() => setActiveTab(2)}>Response</div> : null}
                </div>

                {activeTab === 1 && <>
                    <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab" onClick={e => {
                        e.stopPropagation();
                        e.currentTarget.classList.toggle("route__admin__proxyHistory__singleItem__wrap__content__internalTab--active");
                    }}>
                        <img src="/images/downArrow.svg" />
                        <p>URL</p>
                        <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab__content" onClick={e => e.stopPropagation()}>
                            <FilteredCustomTable
                                headers={["Name", "Value"]}
                                customColumns={["max-content", "1fr"]}
                                style={{columnGap: "20px", width: "100%"}}
                                theme="dark"
                                accent="#232630"
                                data={[
                                    [{keyID: "base-url", type: "text", text: "Base URL"}, {keyID: "base-url", type: "text", text: props.item.Url}],
                                    [{keyID: "full-url", type: "text", text: "Full URL"}, {keyID: "full-url", type: "text", text: getFullURL()}]
                                ]}
                            />
                        </div>
                    </div>

                    <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab" onClick={e => {
                        e.stopPropagation();
                        e.currentTarget.classList.toggle("route__admin__proxyHistory__singleItem__wrap__content__internalTab--active");
                    }}>
                        <img src="/images/downArrow.svg" />
                        <p>Query ({Object.keys(props.item.Query).length})</p>
                        <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab__content" onClick={e => e.stopPropagation()}>
                            <FilteredCustomTable
                                headers={["Name", "Value"]}
                                customColumns={["max-content", "1fr"]}
                                style={{columnGap: "20px", width: "100%"}}
                                theme="dark"
                                accent="#232630"
                                data={Object.keys(props.item.Query).map(key => {
                                    return [
                                        {keyID: key, type: "text", text: key},
                                        {keyID: key, type: "text", text: props.item.Query[key]}
                                    ];
                                })}
                            />
                        </div>
                    </div>

                    <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab" onClick={e => {
                        e.stopPropagation();
                        e.currentTarget.classList.toggle("route__admin__proxyHistory__singleItem__wrap__content__internalTab--active");
                    }}>
                        <img src="/images/downArrow.svg" />
                        <p>Headers ({Object.keys(props.item.RequestHeaders).length})</p>
                        <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab__content" onClick={e => e.stopPropagation()}>
                            <FilteredCustomTable
                                headers={["Name", "Value"]}
                                customColumns={["max-content", "1fr"]}
                                style={{columnGap: "20px", width: "100%"}}
                                theme="dark"
                                accent="#232630"
                                data={Object.keys(props.item.RequestHeaders).map(key => {
                                    return [
                                        {keyID: key, type: "text", text: key},
                                        {keyID: key, type: "text", text: props.item.RequestHeaders[key]}
                                    ];
                                })}
                            />
                        </div>
                    </div>

                    <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab" onClick={e => {
                        e.stopPropagation();
                        e.currentTarget.classList.toggle("route__admin__proxyHistory__singleItem__wrap__content__internalTab--active");
                    }}>
                        <img src="/images/downArrow.svg" />
                        <p>Cookies ({Object.keys(props.item.RequestCookies).length})</p>
                        <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab__content" onClick={e => e.stopPropagation()}>
                            <FilteredCustomTable
                                headers={["Name", "Value"]}
                                customColumns={["max-content", "1fr"]}
                                style={{columnGap: "20px", width: "100%"}}
                                theme="dark"
                                accent="#232630"
                                data={Object.keys(props.item.RequestCookies).map(key => {
                                    return [
                                        {keyID: key, type: "text", text: key},
                                        {keyID: key, type: "text", text: props.item.RequestCookies[key]}
                                    ];
                                })}
                            />
                        </div>
                    </div>

                    <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab" onClick={e => {
                        e.stopPropagation();
                        e.currentTarget.classList.toggle("route__admin__proxyHistory__singleItem__wrap__content__internalTab--active");
                    }}>
                        <img src="/images/downArrow.svg" />
                        <p>Body ({props.item.RequestBody?.["Size"] ? convertBytes(props.item.RequestBody?.["Size"]) : "- B"}) {props.item.RequestBody["Type"]}</p>
                        <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab__content route__admin__proxyHistory__singleItem__wrap__content__internalTab__content--code" onClick={e => e.stopPropagation()}>
                            {typeof(props.item.RequestBody["Data"]) === "object" ? <HighlightedJSON data={props.item.RequestBody["Data"]} /> : <span className="route__admin__proxyHistory__singleItem__wrap__content__internalTab__content__textarea">{props.item.RequestBody?.["Data"]}</span>}
                        </div>
                    </div>
                </>}
                {activeTab === 2 && <>
                    <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab" onClick={e => {
                        e.stopPropagation();
                        e.currentTarget.classList.toggle("route__admin__proxyHistory__singleItem__wrap__content__internalTab--active");
                    }}>
                        <img src="/images/downArrow.svg" />
                        <p>Headers ({Object.keys(props.item.ResponseHeaders).length})</p>
                        <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab__content" onClick={e => e.stopPropagation()}>
                            <FilteredCustomTable
                                headers={["Name", "Value"]}
                                customColumns={["max-content", "1fr"]}
                                style={{columnGap: "20px", width: "100%"}}
                                theme="dark"
                                accent="#232630"
                                data={Object.keys(props.item.ResponseHeaders).map(key => {
                                    return [
                                        {keyID: key, type: "text", text: key},
                                        {keyID: key, type: "text", text: props.item.ResponseHeaders[key]}
                                    ];
                                })}
                            />
                        </div>
                    </div>

                    <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab" onClick={e => {
                        e.stopPropagation();
                        e.currentTarget.classList.toggle("route__admin__proxyHistory__singleItem__wrap__content__internalTab--active");
                    }}>
                        <img src="/images/downArrow.svg" />
                        <p>Cookies ({Object.keys(props.item.ResponseCookies).length})</p>
                        <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab__content" onClick={e => e.stopPropagation()}>
                            <FilteredCustomTable
                                headers={["Name", "Value"]}
                                customColumns={["max-content", "1fr"]}
                                style={{columnGap: "20px", width: "100%"}}
                                theme="dark"
                                accent="#232630"
                                data={Object.keys(props.item.ResponseCookies).map(key => {
                                    return [
                                        {keyID: key, type: "text", text: key},
                                        {keyID: key, type: "text", text: props.item.ResponseCookies[key]}
                                    ];
                                })}
                            />
                        </div>
                    </div>

                    <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab" onClick={e => {
                        e.stopPropagation();
                        e.currentTarget.classList.toggle("route__admin__proxyHistory__singleItem__wrap__content__internalTab--active");
                    }}>
                        <img src="/images/downArrow.svg" />
                        <p>Body ({props.item.ResponseBody?.["Size"] ? convertBytes(props.item.ResponseBody?.["Size"]) : "- B"}) {props.item.ResponseBody["Type"]}</p>
                        <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab__content route__admin__proxyHistory__singleItem__wrap__content__internalTab__content--code" onClick={e => e.stopPropagation()}>
                            {typeof(props.item.ResponseBody["Data"]) === "object" ? <HighlightedJSON data={props.item.ResponseBody["Data"]} /> : <span className="route__admin__proxyHistory__singleItem__wrap__content__internalTab__content__textarea">{props.item.ResponseBody?.["Data"]}</span>}
                        </div>
                    </div>

                    {checkPreviewResponse() && <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab" onClick={e => {
                        e.stopPropagation();
                        e.currentTarget.classList.toggle("route__admin__proxyHistory__singleItem__wrap__content__internalTab--active");
                    }}>
                        <img src="/images/downArrow.svg" />
                        <p>Body preview ({checkPreviewResponse()})</p>
                        <div className="route__admin__proxyHistory__singleItem__wrap__content__internalTab__content route__admin__proxyHistory__singleItem__wrap__content__internalTab__content--preview" onClick={e => e.stopPropagation()}>
                            {createPreview()}
                        </div>
                    </div>}
                </>}

            </div>
        </div>
    </div>
};

const HighlightedJSON = props => {
    function syntaxHighlight(json) {
        try {
            if (typeof json != 'string') {
                json = JSON.stringify(json, undefined, 2);
           }
           json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
           return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
               var cls = 'number';
               if (/^"/.test(match)) {
                   if (/:$/.test(match)) {
                       cls = 'key';
                   } else {
                       cls = 'string';
                   }
               } else if (/true|false/.test(match)) {
                   cls = 'boolean';
               } else if (/null/.test(match)) {
                   cls = 'null';
               }
               return '<span class="' + cls + '">' + match + '</span>';
           });
        } catch {
            return json;
        };
    };

    return <pre dangerouslySetInnerHTML={{__html: syntaxHighlight(props.data)}}></pre>
};

export default AdminProxyHistory;