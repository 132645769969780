export class BigDecimal {
    // Configuration: constants
    static DECIMALS = 2; // number of decimals on all instances
    static ROUNDED = true; // numbers are truncated (false) or rounded (true)
    static SHIFT = window.BigInt("1" + "0".repeat(BigDecimal.DECIMALS)); // derived constant
    constructor(value) {
        if (value instanceof BigDecimal) return value;
        let [ints, decis] = String(value).split(".").concat("");
        this._n = window.BigInt(ints + decis.padEnd(BigDecimal.DECIMALS, "0")
                                     .slice(0, BigDecimal.DECIMALS)) 
                  + window.BigInt(BigDecimal.ROUNDED && decis[BigDecimal.DECIMALS] >= "5");
    }
	/**
	 * 
	 * @param {BigInt} bigint 
	 * @returns {BigDecimal}
	 */
    static fromBigInt(bigint) {
        return Object.assign(Object.create(BigDecimal.prototype), { _n: bigint });
    }
    add(num) {
        return BigDecimal.fromBigInt(this._n + new BigDecimal(num)._n);
    }
    subtract(num) {
        return BigDecimal.fromBigInt(this._n - new BigDecimal(num)._n);
    }
    static _divRound(dividend, divisor) {
        return BigDecimal.fromBigInt(dividend / divisor 
            + (BigDecimal.ROUNDED ? dividend  * 2n / divisor % 2n : 0n));
    }
    multiply(num) {
        return BigDecimal._divRound(this._n * new BigDecimal(num)._n, BigDecimal.SHIFT);
    }
    divide(num) {
        return BigDecimal._divRound(this._n * BigDecimal.SHIFT, new BigDecimal(num)._n);
    }
    toString() {
        let s = this._n.toString().replace("-", "").padStart(BigDecimal.DECIMALS+1, "0");
        s = (s.slice(0, -BigDecimal.DECIMALS) + "." + s.slice(-BigDecimal.DECIMALS))
               .replace(/(\.0*|0+)$/, "");
        return this._n < 0 ? "-" + s : s;
    }
}

export const createRandomString = (length) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   };
   return result;
};

export const convertBytes = bytes => {
	let tmpBytes = new BigDecimal(bytes.toString());

	let suffix = "B";
	while (true) {
		if (suffix === "PB") break;
		if (+tmpBytes.divide(1024) < 1) break;
		tmpBytes = tmpBytes.divide(1024);

		switch (suffix) {
			case "B": suffix = "KB"; break;
			case "KB": suffix = "MB"; break;
			case "MB": suffix = "GB"; break;
			case "GB": suffix = "TB"; break;
			case "TB": suffix = "PB"; break;
		};
	};

	return `${String(+tmpBytes)} ${suffix}`;
};