import React from "react";
import "./index.scss";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import * as backendModule from "../../../modules/backendModule";
import animateModule from "../../../modules/animateModule";
import { animateBox } from "../../../modules/componentAnimation";
import { countries } from "../../../modules/countryModules";
import { convertBytes } from "../../../modules/miscModule";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import FilterPanel from "../../../components/customComponents/FilterPanelNew";
import Spinner from "../../../components/customComponents/Spinner";
import Dropdown from "../../../components/customComponents/Dropdown";
import ButtonWithDropdown from "../../../components/customComponents/ButtonWithDropdown";
import Checkbox from "../../../components/customComponents/Checkbox";
import StyledInput from "../../../components/styledComponents/Input";

import YesNoModal from "../../../components/modals/YesNoModal";

const ProxySMSAssignments = () => {
    const [users, setUsers] = React.useState();
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState();
    const [selectedAssigns, setSelectedAssigns] = React.useState([]);

    const timestampRef = React.useRef();
    const checkboxFunctionsRef = React.useRef();
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    const getUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            ...backendModule.axiosConfig
        }).then(res => {
            setUsers(res.data);
        }).catch(() => {
            setUsers(backendModule.genericError);
        });
    };

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        try {
            checkboxFunctionsRef?.current?.reset?.();
        } catch {};

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/proxySMSAssigns/getAllAssigns`,
            data: {
                limit: 20,
                offset: 0,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);

            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = (ts) => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (timestampRef.current !== ts) return;
        if (!canPaginate) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/proxySMSAssigns/getAllAssigns`,
            data: {
                limit: 20,
                offset: data.data.length,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);

                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    const removeAssignments = (e, IDs) => {
        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text={["This will permanently remove ", <span style={{color: "rgb(108, 93, 211)"}}>{IDs.length} assignments</span>,", and will DISCONNECT the users, if they are using the proxy. Are you really sure?"]}
            isRightButtonNormal={true}
            buttonRightCallback={async args => {
                args.disabledAll(true);
                args.spinner(true);
                args.errorMessage("");

                for (let ID of IDs) {
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/users/proxySMSAssigns/unassignNumber`,
                        data: {
                            ID
                        },
                        ...backendModule.axiosConfig
                    }).then(() => null).catch(() => null);
                };

                let ts = Date.now();
                timestampRef.current = ts;
                getData(ts);
                args.disabledAll(false);
                args.spinner(false);
                args.close();
            }}
        />);
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        curDefer(() => {
            let ts = Date.now();
            timestampRef.current = ts;
            continueData(ts);
        }, 500);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, [filters]);

    React.useEffect(() => {
        getUsers();
    }, []);

    return <div className="route__proxyAssignments">
        <div className="route__proxyAssignments__buttons">
            <div className="route__proxyAssignments__buttons__left">
                <div className={`route__proxyAssignments__buttons__left__selected ${selectedAssigns.length > 0 ? "route__proxyAssignments__buttons__left__selected--active" : ""}`}>
                    {`${selectedAssigns.length} assigns selected`}
                    <div
                        className="route__proxyAssignments__buttons__left__selected__btn"
                        style={{ backgroundImage: `url("/images/icon_close.svg")` }}
                        onClick={() => checkboxFunctionsRef.current?.reset()}
                    ></div>
                </div>
            </div>
            <div className="route__proxyAssignments__buttons__right">
                {selectedAssigns.length === 0 && <div className="route__proxyAssignments__buttons__btn" onClick={e => {
                    animateBox(e, <Assign onChange={() => {
                        let t = Date.now();
                        timestampRef.current = t;
                        getData(t);
                    }} />)
                }}>
                    <img src="/images/icon_close.svg" style={{transform: "rotate(45deg)"}} />
                    <span>Assign</span>
                </div>}
                {selectedAssigns.length > 0 && <ButtonWithDropdown
                    image="/images/icon_edit.svg"
                    value="More actions"
                    data={[
                        { name: `Remove ${selectedAssigns.length} assigns`, onClick: (e) => removeAssignments(e, selectedAssigns) }
                    ].filter(t => t)}
                />}
            </div>
        </div>

        <FilterPanel
            theme="dark"
            accent="rgb(62, 87, 166)"
            filters={[
                {name: "UserID", friendlyName: "User", type: "custom", varType: "string", data: (users?.status === "ok" ? users.data : []).map(usr => {
                    return {text: usr.Username, value: usr.ID}
                })},
                {name: "Country", friendlyName: "Country", type: "custom", varType: "string", data: countries.map(c => {
                    return {text: c.name, value: c.code.toUpperCase()}
                })},
                {name: "AssignedNumber", friendlyName: "Assigned number", type: "string"},
                {name: "Description", friendlyName: "Description", type: "string"},
            ]}
            filterCB={f => {
                setFilters(f);
            }}
        />

        <FilteredCustomTable
            accent="#6C5DD3"
            theme="dark"
            checkboxCB={(data?.status === "ok" && data?.data?.length > 0) ? setSelectedAssigns : false}
            checkboxFunctions={cf => checkboxFunctionsRef.current = cf()}
            headers={["User", "Country", "Number", "Date"]}
            customColumns={["auto", "auto", "auto", "auto"]}
            style={{columnGap: "20px", width: "100%"}}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "Error while fetching data!"}]];

                let out = [];
                for (let item of data.data) {
                    let selectedCountry = countries.find(c => c.code === item.Country);
                    selectedCountry = selectedCountry ? selectedCountry.name : "Any";
                    out.push([
                        {keyID: item.ID, type: "text", text: item._User?.Username},
                        {keyID: item.ID, type: "text", text: selectedCountry},
                        {keyID: item.ID, type: "text", text: item.AssignedNumber},
                        {keyID: item.ID, type: "text", text: (new Date(item.createdAt)).toLocaleString()},
                        (item.Description ? {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "text", text: item.Description, style: {color: "#a49d9d"}}
                        ]} : null)
                    ].filter(f => f));
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show."}]);

                return out;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{width: "1px", height: "1px", opacity: 0}}></div>}
    </div>
};

const Assign = props => {
    const [users, setUsers] = React.useState();
    const [numbers, setNumbers] = React.useState();
    const [selectedUser, setSelectedUser] = React.useState();
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [selectedNumber, setSelectedNumber] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        inputs: [],
        hadError: false,
        text: ""
    });

    const descriptionRef = React.useRef();

    const getUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            ...backendModule.axiosConfig
        }).then(res => {
            setUsers(res.data);
        }).catch(() => {
            setUsers(backendModule.genericError);
        });
    };

    const getUnusedNumbers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/proxySMSAssigns/getUnusedNumbers`,
            data: {
                filters: [
                    selectedCountry ? {name: "InterfaceCountry", op: "eq", value: selectedCountry} : null
                ].filter(f => f)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setNumbers(res.data);
        }).catch(() => {
            setNumbers(backendModule.genericError);
        });
    };

    const assignProxy = () => {
        if (spinner) return;
        setInfoP(ip => {return {...ip, hadError: false, inputs: []}});

        let data = {
            UserID: selectedUser,
            InterfaceID: selectedNumber,
            Description: descriptionRef.current.value
        };

        if (!data.UserID) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["ussername"], text: "Select an user first"}});
        if (!data.InterfaceID) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["number"], text: "Select a number first"}});

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/proxySMSAssigns/assignNumber`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                props.onClose();
            } else {
                setInfoP(ip => {return {...ip, inputs: [], hadError: true, text: "There was an error while assigning a number to the user (taken already?)"}});
            };
        }).catch(() => {
            setInfoP(ip => {return {...ip, inputs: [], hadError: true, text: "Server timed out!"}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (selectedCountry === undefined) return;
        if (selectedNumber) setSelectedNumber();
        getUnusedNumbers();
    }, [selectedCountry]);

    React.useEffect(() => {
        getUsers();
    }, []);

    return <div className="route__proxyAssignments__assign genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Assign</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            {users ? <>
                {users.status === "ok" ? <>
                    <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("username") ? "genericModal__wrap__input--error" : ""}`}>
                        <p>Select an user</p>
                        <Dropdown
                            theme="dark"
                            accent="#6C5DD3"
                            inlinePlaceholder="User"
                            data={users.data.map(usr => {
                                return {name: usr.Username, value: usr.ID};
                            })}
                            onChange={e => setSelectedUser(e?.value)}
                        />
                    </div>
                    {selectedUser && <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("country") ? "genericModal__wrap__input--error" : ""}`}>
                        <p>Select a country</p>
                        <Dropdown
                            theme="dark"
                            accent="#6C5DD3"
                            inlinePlaceholder="Country"
                            data={[{name: "Any country", value: null}, ...(()=>{
                                let out = [];
                                let foundUser = users.data.find(u => u.ID === selectedUser);
                                if (!foundUser) return [];
                                if (!Array.isArray(foundUser.Countries)) return [];
                                if (foundUser.Countries.length === 0) return [];

                                for (let country of foundUser.Countries) {
                                    out.push({name: country.name, value: country.code});
                                };

                                return out;
                            })()]}
                            onChange={e => setSelectedCountry(e?.value)}
                        />
                    </div>}

                    {(selectedCountry !== undefined && !numbers) && <Spinner style={{width: "16px", height: "16px"}} color="white" />}
                    {numbers && <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("number") ? "genericModal__wrap__input--error" : ""}`}>
                        <p>Select a number</p>
                        <Dropdown
                            theme="dark"
                            accent="#6C5DD3"
                            inlinePlaceholder="Phone number"
                            data={(numbers.status === "ok" ? numbers.data : []).map(num => {
                                return {name: `(${num.InterfaceCountry}) ${num.InterfacePhoneNumber}`, value: num.ID}
                            })}
                            onChange={e => setSelectedNumber(e?.value)}
                        />
                    </div>}

                    {numbers && <div className="genericModal__wrap__input">
                        <p>Description</p>
                        <input ref={descriptionRef} type="text" placeholder="Description" />
                    </div>}

                    <div className="genericModal__wrap__buttons">
                        <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                        <div className="genericModal__wrap__buttons__btn" onClick={e => assignProxy()}>
                            {spinner ? <Spinner style={{width: "24px", height: "24px"}} color="white" /> : "Save"}
                        </div>
                    </div>

                    {infoP.text && <div className="genericModal__wrap__infoP" style={{opacity: infoP.hadError ? 1 : 0}}>{infoP.text}</div>}
                </> : <p className="genericModal__wrap__infoP" style={{opacity: 1}}>Error while fetching users!</p>}
            </> : <Spinner color="white" />}
        </div>
    </div>
};

export default ProxySMSAssignments;