const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CHAT_ADD":
            let finalObj = { ...state };
            for (let key of Object.keys(action.payload)) {
                if (!finalObj[key]) {
                    finalObj[key] = action.payload[key];
                } else {
                    finalObj[key] = [...finalObj[key], ...action.payload[key]]
                    for (let curChat of action.payload[key]) {
                        if (curChat.Type === "image" || curChat.Type === "file" || curChat.Type === "video") {
                            let curData = {};
                            try {
                                curData = JSON.parse(curChat.Content);
                            } catch {
                                curData = {};
                            };
                            if (curData?.rnd) {
                                finalObj[key] = finalObj[key].filter(tmp => {
                                    if (!tmp.rnd) return true;
                                    if (tmp.rnd === curData.rnd) return false;
                                    return true;
                                });
                            };
                        };
                    };
                    if (finalObj[key].length > 0) {
                        finalObj[key] = finalObj[key].sort((a, b) => a.ID > b.ID ? 1 : -1);
                        finalObj[key] = finalObj[key].map((item, index) => {
                            return {
                                ...item,
                                hasSpinner: (index === 0 && finalObj[key].length > 39) ? true : false
                            };
                        });
                    }
                };
            };
            return finalObj
        case "CHAT_REMOVE":
            let finalObj2 = { ...state };
            if (finalObj2[action.payload.RoomID]) {
                finalObj2[action.payload.RoomID] = finalObj2[action.payload.RoomID].filter(tmp => tmp.ID !== action.payload.ID);
            }
            return finalObj2;
        case "CHAT_BULK_ADD":
            for (let key of Object.keys(action.payload)) {
                if (Array.isArray(action.payload[key])) action.payload[key] = action.payload[key].sort((a, b) => a.ID > b.ID ? 1 : -1);
                if (action.payload[key].length > 0) {
                    action.payload[key] = action.payload[key].map((item, index) => {
                        return {
                            ...item,
                            hasSpinner: index === 0 ? (action.payload[key].length > 39 ? true : false) : false
                        }
                    })
                }
            }

            return { ...state, ...action.payload };
        case "CHAT_CLEAN": return {};
        case "CHAT__SPINNER__REMOVE":
            return {
                ...state,
                [action.payload.ChatRoomID]: [
                    ...state[action.payload.ChatRoomID].filter((tmp) => tmp.ID < action.payload.ChatID),
                    ...state[action.payload.ChatRoomID].filter((tmp) => tmp.ID === action.payload.ChatID).map(t => {
                        t.hasSpinner = false;
                        return t;
                    }),
                    ...state[action.payload.ChatRoomID].filter((tmp) => tmp.ID > action.payload.ChatID),
                ]
            }
        case "CHAT_UPDATE":
            return {
                ...state,
                [action.payload.ChatRoomID]: [
                    ...state[action.payload.ChatRoomID].filter((tmp) => tmp.ID < action.payload.ChatID),
                    ...state[action.payload.ChatRoomID].filter((tmp) => tmp.ID === action.payload.ChatID).map(t => {
                        t.Type = action.payload.MessageType;
                        t.Content = action.payload.MessageContent;
                        return t;
                    }),
                    ...state[action.payload.ChatRoomID].filter((tmp) => tmp.ID > action.payload.ChatID),
                ].filter(final => final.Type !== "preload")
            }
        case "CHAT__RESEND":
            return {
                ...state,
                [action.payload.ChatRoomID]: [
                    ...state[action.payload.ChatRoomID].filter((tmp) => tmp.ID < action.payload.ChatID),
                    ...state[action.payload.ChatRoomID].filter((tmp) => tmp.ID === action.payload.ChatID).map(t => {
                        t.Type = "resend";
                        t.Content = action.payload.MessageContent;
                        return t;
                    }),
                    ...state[action.payload.ChatRoomID].filter((tmp) => tmp.ID > action.payload.ChatID),
                ].filter(final => final.Type !== "preload")
            }
        default: return state;
    };
};