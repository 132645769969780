import React from "react";
import "./index.scss";

import axios from "axios";
import * as backendModule from "../../../modules/backendModule";

import Spinner from "../../customComponents/Spinner";

const ChangePassword = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        hadError: false,
        inputs: [],
        error: "-"
    });

    const oldPasswordRef = React.useRef();
    const newPasswordRef = React.useRef();
    const confirmPasswordRef = React.useRef();

    const onClose = (force = false) => {
        if (spinner && !force) return;

        if (props.onChange) props.onChange();
        props.onClose();
    };

    const changePassword = () => {
        if (spinner) return;

        setInfoP(i => {
            return {
                ...i,
                hadError: false,
                inputs: []
            };
        });

        const data = {
            oldPassword: oldPasswordRef.current.value,
            newPassword: newPasswordRef.current.value,
            confirmNewPassword: confirmPasswordRef.current.value
        };

        if (!data.oldPassword || !data.newPassword || !data.confirmNewPassword) {
            return setInfoP(i => {return {...i, hadError: true, error: "Fields can't be empty", inputs: ["oldPassword", "newPassword", "confirmNewPassword"]}});
        };
        if (data.newPassword !== data.confirmNewPassword) return setInfoP(i => {
            return {
                ...i,
                hadError: true,
                error: "Passwords don't match",
                inputs: ["newPassword", "confirmNewPassword"]
            };
        });
        
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/changePassword`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                onClose(true);
            } else {
                setInfoP(i => {
                    return {...i, hadError: true, error: "Old password incorrect or new password invalid."}
                });
            };
        }).catch(() => {
            setInfoP(i => {return {...i, hadError: true, error: "Server timed out."}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="component__header__changePassword" onClick={e => e.stopPropagation()}>
        <div className="component__header__changePassword__wrap">
            <div className="component__header__changePassword__wrap__head">
                <p>Change password</p>

                <div
                    className="component__header__changePassword__wrap__head__btn"
                    style={{
                        backgroundImage: `url("/images/icon_close.svg")`
                    }}
                    onClick={() => onClose()}
                ></div>
            </div>

            <div className={`component__header__changePassword__wrap__input ${infoP.inputs.includes("oldPassword") ? "component__header__changePassword__wrap__input--error" : ""}`}>
                <span>Current password</span>
                <input ref={oldPasswordRef} type="password" placeholder="Current password" />
            </div>

            <div className={`component__header__changePassword__wrap__input ${infoP.inputs.includes("newPassword") ? "component__header__changePassword__wrap__input--error" : ""}`}>
                <span>New password</span>
                <input ref={newPasswordRef} type="password" placeholder="New password" />
            </div>

            <div className={`component__header__changePassword__wrap__input ${infoP.inputs.includes("confirmNewPassword") ? "component__header__changePassword__wrap__input--error" : ""}`}>
                <span>Confirm password</span>
                <input ref={confirmPasswordRef} type="password" placeholder="Confirm password" />
            </div>

            <div className="component__header__changePassword__wrap__btns">
                <div className="component__header__changePassword__wrap__btns__btn component__header__changePassword__wrap__btns__btn--secondary" onClick={() => onClose()}>Close</div>
                <div className="component__header__changePassword__wrap__btns__btn" onClick={changePassword}>
                    {spinner ? <Spinner style={{width: "32px", height: "32px"}} color="white" /> : "Save"}
                </div>
            </div>

            <p className={`component__header__changePassword__wrap__infoP ${infoP.hadError ? "component__header__changePassword__wrap__infoP--error" : ""}`}>{infoP.error}</p>
        </div>
    </div>
};

export default ChangePassword;