import React from "react";
import "./index.scss";

import axios from "axios";

import { animateBox } from "../../../modules/componentAnimation";
import { useDispatch } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import * as siteFunctionsActions from "../../../actions/siteFunctionsActions";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import FilterPanel from '../../../components/customComponents/FilterPanelNew';
import DataSorter from "../../../components/customComponents/DataSorter";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import ButtonWithDropdown from "../../../components/customComponents/ButtonWithDropdown";
import Dropdown from "../../../components/customComponents/Dropdown";
import Spinner from "../../../components/customComponents/Spinner";
import Checkbox from "../../../components/customComponents/Checkbox";
import Button from '../../../components/customComponents/Button';
import CustomButtonSmall from '../../../components/customComponents/Button';

import YesNoModal from "../../../components/modals/YesNoModal";

const AdminApiTokens = () => {
  const [allFlags, setAllFlags] = React.useState();
  const [data, setData] = React.useState();
  const [selectedTokens, setSelectedTokens] = React.useState([]);
  const [canPaginate, setCanPaginate] = React.useState(false);
  const [filters, setFilters] = React.useState(false);

  const checkboxFunctionsRef = React.useRef();
  const timestampRef = React.useRef();
  const curOnScreen = useOnScreen();
  const curDefer = useDefer();
  const curDispatch = useDispatch();

  const getAllFlags = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/apiTokens/getAllAvailableFlags`,
      ...backendModule.axiosConfig
    }).then(res => {
      setAllFlags(res.data);
    }).catch(() => {
      setAllFlags(backendModule.genericError);
    });
  };

  const getData = ts => {
    setCanPaginate(false);
    if (timestampRef.current !== ts) return;

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/apiTokens/getAllApiTokens`,
      data: {
        filters: filters,
        limit: 20,
        offset: 0
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (timestampRef.current !== ts) return;
      setData(res.data);
      if (res.data.status === "ok") {
        if (res.data.data.length >= 20) setCanPaginate(true);
      };
    }).catch(() => {
      if (timestampRef.current !== ts) return;
      setData(backendModule.genericError);
    });
  };

  const continueData = ts => {
    if (!data) return;
    if (data.status !== "ok") return;
    if (!canPaginate) return;

    setCanPaginate(false);
    if (timestampRef.current !== ts) return;

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/apiTokens/getAllApiTokens`,
      data: {
        limit: 20,
        offset: data.data.length,
        filters: filters
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (timestampRef.current !== ts) return;
      if (res.data.status === "ok") {
        if (res.data.data.length >= 20) setCanPaginate(true);
        setData(d => {
          return {
            ...d,
            data: [
              ...d.data,
              ...res.data.data
            ]
          };
        });
      };
    }).catch(() => null);
  };

  const editToken = (e, id) => {

    if (!data) return;
    if (data.status === "error") return;


    let curToken = data.data.find(d => d.ID === id);
    if (!curToken) return;

    animateBox(e, <AddToken flags={allFlags?.status === "ok" ? allFlags.data : []} edit={curToken} onChange={() => {
      let ts = Date.now();
      timestampRef.current = ts;
      getData(ts);
    }} />)
  };

  const removeTokens = (e, ids) => {
    if (ids.length === 0) return;

    animateBox(e, <YesNoModal
      heading="Are you sure?"
      text={["You are about to remove ", <span style={{ color: "rgb(152, 138, 244)" }}>{ids.length} token</span>, ", are you sure?"]}
      isRightButtonNormal={true}
      buttonRightCallback={async args => {
        args.disabledAll(true);
        args.spinner(true);

        for (let item of ids) {
          await axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/apiTokens/removeApiToken`,
            data: {
              ID: item
            },
            ...backendModule.axiosConfig
          }).then(() => null).catch(() => null);
        };

        args.disabledAll(false);
        args.spinner(false);
        args.close();
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
      }}
    />);
  };

  React.useEffect(() => {
    if (!canPaginate) return;
    if (!curOnScreen.isIntersecting) return;

    try {
      curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
    } catch { };
    let ts = Date.now();
    timestampRef.current = ts;
    curDefer(() => continueData(ts));
  }, [canPaginate, curOnScreen.isIntersecting]);

  React.useEffect(() => {
    const handler = () => {
      let ts = Date.now();
      timestampRef.current = ts;
      getData(ts);
    };
    handler();
    getAllFlags();

    curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

    return () => {
      try {
        siteFunctionsActions.removeHeaderRefreshAction(handler);
      } catch { };
    };
  }, [filters]);

  return <div className="route__admin__apiTokens">
    <div className="route__admin__apiTokens__btns">
      <div className="route__admin__apiTokens__btns__left">
        <div className={`route__admin__apiTokens__btns__left__selected ${selectedTokens.length > 0 ? "route__admin__apiTokens__btns__left__selected--active" : ""}`}>
          {`${selectedTokens.length} tokens selected`}
          <div
            className="route__admin__apiTokens__btns__left__selected__btn"
            style={{ backgroundImage: `url("/images/icon_close.svg")` }}
            onClick={() => checkboxFunctionsRef.current?.reset()}
          ></div>
        </div>
      </div>

      <div className="route__admin__apiTokens__btns__right">
        {selectedTokens.length > 0 && <ButtonWithDropdown
          image="/images/icon_edit.svg"
          value="More actions"
          data={[
            (selectedTokens.length === 1 ? { name: "View token", onClick: e => animateBox(e, <ViewTokens ID={selectedTokens[0]} />) } : null),
            (selectedTokens.length === 1 ? { name: "Edit token", onClick: e => editToken(e, selectedTokens[0]) } : null),
            { name: `Remove ${selectedTokens.length} tokens`, onClick: e => removeTokens(e, selectedTokens[0]) }
          ].filter(t => t)}
        />}

        {selectedTokens.length === 0 && <div className="route__admin__apiTokens__btns__right__btn" onClick={e => {
          animateBox(e, <AddToken flags={allFlags?.status === "ok" ? allFlags.data : []} onChange={() => {
            let ts = Date.now();
            timestampRef.current = ts;
            getData(ts);
          }} />);
        }}>
          <img src="/images/icon_close.svg" style={{ transform: "rotate(45deg)" }} />
          <span>Add token</span>
        </div>}
      </div>
    </div>

    <FilterPanel
      theme="dark"
      accent="rgb(62, 87, 166)"
      filters={[
        { name: "createdAt", friendlyName: "Date created", type: "date" },
        { name: "Name", friendlyName: "Name", type: "string" },
      ]}
      filterCB={f => {
        setFilters(f);
      }}
    />

    <div className="route__admin__apiTokens__tableWrap">
      <FilteredCustomTable
        style={{ width: "100%" }}
        key="admin-users-table"
        checkboxCB={(data?.status === "ok" && data?.data?.length > 0) ? setSelectedTokens : false}
        checkboxFunctions={cf => checkboxFunctionsRef.current = cf()}
        accent="#6C5DD3"
        theme="dark"
        headers={["Username", "Name", "Date created"]}
        data={(() => {
          let out = [];

          if (!data || !allFlags) {
            out.push([{ keyID: "noData-spinner", type: "spinner", color: "white" }]);
          } else {
            if (data.status === "ok" && allFlags.status === "ok") {
              for (let item of data.data) {
                out.push([
                  { keyID: item.ID, type: "text", text: item.User },
                  { keyID: item.ID, type: "text", text: item.Name },
                  { keyID: item.ID, type: "text", text: (new Date(item.createdAt).toLocaleDateString()) },
                  {
                    keyID: item.ID, type: "groupNewline", group: [
                      {
                        keyID: item.ID, type: "custom", data: <p className="route__admin__apiTokens__tableWrap__pills">
                          <p>Roles:</p>
                          {Object.keys(item.Flags).map(key => {
                            if (!item.Flags[key]) return null;
                            let foundFlag = allFlags.data.find(f => f.name === key);
                            if (!foundFlag) return null;
                            return <span className="route__admin__apiTokens__tableWrap__pills__pill">{foundFlag.friendlyName}</span>
                          })}
                        </p>
                      }
                    ]
                  }
                ]);
              };
            } else {
              out.push([{ keyID: "noData-error", type: "text", text: "An error occured!" }]);
            };
          };

          if (out.length === 0) {
            out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show..." }]);
          };
          return out;
        })()}
      />
      {canPaginate && <div style={{ opacity: 0 }} ref={curOnScreen.measureRef} />}
    </div>
  </div>
};

const AddToken = props => {
  const [users, setUsers] = React.useState();
  const [selectedUser, setSelectedUser] = React.useState(props?.edit?.UserID ?? undefined);
  const [flags, setFlags] = React.useState({});
  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState({
    inputs: [],
    hadError: false,
    text: ""
  });

  const tokenNameRef = React.useRef();

  const getUsers = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/getAllUsers`,
      data: {
        limit: 999999,
        offset: 0
      },
      ...backendModule.axiosConfig
    }).then(res => {
      setUsers(res.data)
    }).catch(() => {
      setUsers(backendModule.genericError);
    });
  };

  const saveData = () => {
    if (spinner) return;
    setInfoP(ip => { return { ...ip, inputs: [], hadError: false } });

    let flg = {};
    for (let key of Object.keys(flags)) {
      if (flags[key]) flg[key] = true;
    };

    let data = {
      UserID: selectedUser,
      Name: tokenNameRef.current.value,
      Flags: flg
    };

    if (props.edit) data["ID"] = props.edit.ID;

    if (!data.UserID) return setInfoP(ip => { return { ...ip, inputs: ["user"], hadError: true, text: "User must be selected" } });
    if (!data.Name) return setInfoP(ip => { return { ...ip, inputs: ["name"], hadError: true, text: "Name can't be empty" } });
    if (Object.keys(data.Flags).length === 0) return setInfoP(ip => { return { ...ip, inputs: [], hadError: true, text: "At least 1 role must be selected!" } });

    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/apiTokens/${props.edit ? "editApiToken" : "addApiToken"}`,
      data,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        if (props.onChange) props.onChange();
        props.onClose();
      } else {
        return setInfoP(ip => { return { ...ip, inputs: [], hadError: true, text: "Therre was an error while creating the token!" } });
      };
    }).catch(() => {
      return setInfoP(ip => { return { ...ip, inputs: [], hadError: true, text: "Server timed out!" } });
    }).finally(() => {
      setSpinner(false);
    });
  };

  React.useEffect(() => {
    if (!props.edit) return;

    let flg = {};
    for (let key of Object.keys(props.edit.Flags)) {
      if (props.edit.Flags[key]) flg[key] = true;
    };

    setFlags(flg);
  }, [props.edit]);

  React.useEffect(() => {
    getUsers();
  }, []);

  return <div className="genericModal">
    <div className="genericModal__wrap">
      <div className="genericModal__wrap__head">
        <div className="genericModal__wrap__head__left">{props.edit ? "Edit" : "Add"} token</div>
        <div className="genericModal__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={props.onClose}></div>
      </div>

      {users ? <>
        {users.status === "ok" ? <>
          <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("user") ? "genericModal__wrap__input--error" : ""}`}>
            <p>Selected user</p>
            <Dropdown
              theme="dark"
              accent="#6C5DD3"
              inlinePlaceholder="Selected user"
              data={users.data.map(usr => {
                return { name: usr.Username, value: usr.ID }
              })}
              onChange={e => setSelectedUser(e?.value)}
              selected={(() => {
                if (!selectedUser) return null;
                return users.data.indexOf(users.data.find(u => u.ID === selectedUser));
              })()}
            />
          </div>

          <div className={`genericModal__wrap__input ${infoP.inputs.includes("name") ? "genericModal__wrap__input--error" : ""}`}>
            <p>Token name</p>
            <input ref={tokenNameRef} defaultValue={props?.edit?.Name ?? ""} type="text" placeholder="Token name" />
          </div>

          {props.flags.map(flg => {
            return <div className="genericModal__wrap__checkbox">
              <Checkbox defaultValue={!!flags[flg.name]} onChange={e => {
                if (e) {
                  setFlags(f => {
                    return {
                      ...f,
                      [flg.name]: true
                    };
                  });
                } else {
                  setFlags(f => {
                    let tmp = { ...f };
                    delete tmp[flg.name];
                    return tmp;
                  });
                };
              }} />
              <span>{flg.friendlyName}</span>
            </div>
          })}

          <div className="genericModal__wrap__buttons">
            <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
            <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && saveData()}>
              {spinner ? <Spinner style={{ width: "16px", height: "16px" }} color="white" /> : "Save"}
            </div>
          </div>
        </> : <p className="genericModal__wrap__infoP" style={{ opacity: 1 }}>There was an error while fetching data!</p>}
      </> : <Spinner color="white" />}

      {infoP.text && <p className="genericModal__wrap__infoP" style={{ opacity: infoP.hadError ? 1 : 0 }}>{infoP.text}</p>}
    </div>
  </div>
};

const ViewTokens = (props) => {
  const [token, setToken] = React.useState();

  const getToken = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/apiTokens/getToken`,
      data: {
        ID: props.ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      setToken(res.data);
    }).catch(() => {
      setToken(backendModule.genericError);
    });
  };

  React.useEffect(() => {
    getToken();
  }, []);

  return <div className="genericModal">
    <div className="genericModal__wrap">
      <div className="genericModal__wrap__head">
        <div className="genericModal__wrap__head__left">View token</div>
        <div className="genericModal__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={props.onClose}></div>
      </div>

      {token ? <>
        {token.status === "ok" ? <>
          <p>Copy the following token:</p>
          <br />
          <p style={{
            width: "100%",
            wordBreak: "break-all",
            color: "rgb(152, 138, 244)",
            userSelect: "all"
          }}>{token.data}</p>

          <div className="genericModal__wrap__buttons">
            <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
          </div>
        </> : <p className="genericModal__wrap__infoP" style={{ opacity: 1 }}>There was an error while fetching the token!</p>}
      </> : <Spinner color="white" />}
    </div>
  </div>
};

const ShowToken = (props) => {
  const [data, setData] = React.useState();

  React.useEffect(() => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/apiTokens/getToken`,
      data: {
        ID: props.ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data);
      } else {
        setData({ status: "error", data: "SERVER_ERROR" });
      };
    }).catch(() => {
      setData({ status: "error", data: "SERVER_ERROR" });
    });
  }, [])

  return <div className="route__adminPanel__apiTokens__showToken">
    <br />
    {(() => {
      if (!data) return <Spinner style={{ width: "64px", height: "64px" }} color="#6664E5" />
      if (data.status === "error") return <p>Error while getting Token</p>
      return <>
        <span>Token: </span>
        <span style={{
          color: "#6664E5"
        }}>{data.data}</span>
      </>
    })()}
    <br />
    <CustomButtonSmall style={{ marginTop: "20px", border: "1px solid rgb(233, 86, 86)" }} accent="#2b2c3e" theme="dark" value="Close" onClick={() => props.onClose()} />
  </div>
};

export default AdminApiTokens;