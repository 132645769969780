import React from "react";
import "./index.scss";
import PropTypes from "prop-types";

const CustomInput = React.forwardRef((props, ref) => {
    const mainRef = React.useRef();
    const themeData = (props.theme === "light" || props.theme === "dark") ? props.theme : "light"

    let tmpAttrs = {...props};
    delete tmpAttrs.ref;
    delete tmpAttrs.style;
    delete tmpAttrs.type;
    delete tmpAttrs.theme;
    delete tmpAttrs.accent;
    delete tmpAttrs.placeholder;

    React.useEffect(() => {
        if (!mainRef.current) return;
        const placeholderShown = !!mainRef.current.querySelector("*:placeholder-shown");
        
        switch (props.type) {
            case "text":
            case "number":
            case "password":
            case undefined:
            case null:
                mainRef.current.querySelector(".customComponents__input__placeholder").style.color = null;
                break;
            default: {
                if (!placeholderShown && props.accent) {
                    mainRef.current.querySelector(".customComponents__input__placeholder").style.color = props.accent;
                };
                break;
            };
        }
    }, [props.type]);

    React.useEffect(() => {
        setTimeout(() => {
            if (!mainRef.current) return;
            const placeholderShown = !!mainRef.current.querySelector("*:placeholder-shown");
            if (!placeholderShown && props.accent) {
                mainRef.current.querySelector(".customComponents__input__placeholder").style.color = props.accent;
            };
        }, 0)
    }, []);
    
    return <div ref={mainRef} className={`customComponents__input customComponents__input--${themeData}`} style={{
        ...(props.style ?? {})
    }}>
        {props.isPhone ? <p className="customComponents__input__plus">+</p>:''}
        <input ref={ref} placeholder="" className="customComponents__input__textbox" type={props.type ?? "text"} style={{
            borderBottomColor: props.accent ?? null,
            ...(props.style ? props.style : {})
        }} onChange={e => {
            if (props.onChange) props.onChange(e);
            if (!props.accent) return;

            const placeholderShown = !!e.currentTarget.parentNode.querySelector("*:placeholder-shown");
            e.currentTarget.parentNode.querySelector(".customComponents__input__placeholder").style.color = placeholderShown ? null : props.accent;
        }} {...tmpAttrs} />
        <span className="customComponents__input__placeholder">{props.placeholder ?? ""}</span>
    </div>
});

CustomInput.propTypes = {
    theme: PropTypes.oneOf(["light", "dark"]),
    type: PropTypes.oneOf(["text", "number", "password", "date", "time", "color"]),
    placeholder: PropTypes.string,
    style: PropTypes.object,
    accent: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func
};

CustomInput.displayName = "CustomInput";

export default CustomInput